<div class="bg-blueGray-100 flex items-start justify-center w-full h-screen">
  <div class="md:w-[454px] flex flex-col items-center justify-start gap-6 py-[90px]">
    @if (type===VerifyPage.verifySuccess){
    <img src="/assets/images/Account Verify Succeed.svg" alt="" />
    } @else {
    <img src="/assets/images/Sorry.svg" alt="" />}
    <div class="col bg-white rounded-lg p-6 gap-6">
      <h4 class="text-center">@if (type===VerifyPage.verifySuccess){ Xác thực thành công! } @else { Có lỗi xảy ra}</h4>
      @if (type===VerifyPage.verifySuccess){
      <span
        >Chúc mừng bạn đã xác thực thành công địa chỉ email của mình. Hãy đăng nhập và học “thông minh” cùng AI của
        Kyons nhé.</span
      >
      }
      <span
        >Nếu bạn nghĩ đó là lỗi? Liên hệ <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để
        được hỗ trợ kịp thời.</span
      >
      <div class="col gap-2">
        @if (type===VerifyPage.verifySuccess){
        <a [routerLink]="paths.signIn.path" class="btn orange">Đăng nhập ngay thôi</a>
        } @else { @if(errorMessage) {
        <button (click)="resend()" class="btn orange">Gửi lại email xác thực</button>
        }}
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="hasError"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/warning.webp" alt="" />
    <div class="col w-full gap-6">
      <h5 class="text-center">Chú ý! Chú ý!</h5>
      <span>
        Hệ thống hiện đang gặp sự cố, bạn vui lòng thử lại sau vài phút hoặc liên hệ
        <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để được hỗ trợ kịp thời!
      </span>
      <b>Mã lỗi: {{ errorMessage }}</b>
      <button (click)="hasError = false" class="btn orange w-full">Mình đã hiểu</button>
    </div>
  </div>
</div>

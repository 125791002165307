import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Injector, OnDestroy, OnInit, Renderer2, effect, inject, runInInjectionContext } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ThemeService } from '@data/theme/theme.service';
import { UserService } from '@data/user/user.service';
import { cancel } from '@share-utils/utils';
import { AiMenuComponent } from '@view/share-components/ai-menu/ai-menu.component';
import { TrackingComponent } from '@view/share-components/tracking/tracking.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  template: '<student-tracking style="display: contents"></student-tracking><ai-menu /><router-outlet></router-outlet>',
  imports: [RouterModule, TrackingComponent, AiMenuComponent],
})
export class LayoutAIComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'app-full app-bg';

  injector = inject(Injector);
  router = inject(Router);
  document = inject(DOCUMENT);
  renderer = inject(Renderer2);
  subscription!: Subscription;
  userService = inject(UserService);
  themeService = inject(ThemeService);
  theme = this.themeService.themeStore();
  skin = this.themeService.skinStore();

  ngOnInit(): void {
    this.userService.updateCurrentUser();
    this.renderer.setAttribute(this.document.body, 'data-theme', 'default');
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.skin = this.themeService.skinStore();
        this.theme = this.themeService.themeStore();
        this.renderer.setAttribute(this.document.body, 'data-theme', this.theme);
        this.renderer.setAttribute(this.document.body, 'data-skin', this.skin);
      });
    });
  }

  ngOnDestroy() {
    cancel([this.subscription]);
    this.renderer.setAttribute(this.document.body, 'data-theme', this.themeService.skinStore());
  }
}

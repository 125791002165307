<div class="workspaces">
  <div class="w-full flex px-4 justify-center min-h-[4rem]">
    <div class="flex justify-center pt-6 w-full max-w-[960px]">
      @if(searchFocus){
      <button class="btn-icon link z-[2]" (click)="searchFocus = false">
        <i class="icon-ArrowBackward"></i>
      </button>
      }
      <h3 class="hidden lg:inline text-center w-full title">Không gian làm việc của bạn 🚀</h3>
      <h5 class="inline lg:hidden semi text-center w-full title">Không gian làm việc của bạn</h5>
    </div>
  </div>
  <div class="flex justify-center px-4 z-[1]">
    <div class="w-full max-w-[960px] border rounded-3xl col search">
      <div class="relative flex gap-1 px-3 py-1 items-center z-[1]">
        <i class="icon-Search pointer-events-none text-base"></i>
        <input
          type="text"
          name="searchChat"
          placeholder="Tìm kiếm..."
          [(ngModel)]="searchChat"
          (ngModelChange)="onSearchChat()"
          class="flex-1"
          (focus)="onFocus()"
        />
      </div>
      @if(searchFocus){
      <hr class="w-full" />
      <div class="col md:px-6 px-10 py-4 gap-8 w-full relative z-[1]">
        <!-- <div class='col gap-4'>
          Từ khóa gần đây
          <div class='w-full'>
            <a href=""></a>
          </div>
        </div> -->
        <div class="col gap-4">
          <span class="h7 semi">Không gian làm việc</span>
          <div class="relative w-full">
            <div #workspacesScroll class="w-full overflow-auto hide-scrollbar scroll-smooth flex gap-4">
              @for(wp of filteredWorkspaces;track wp.id;let i = $index){<a
                class="workspace-item"
                [routerLink]="paths.workspaceDetails.path.replace(':id', wp.id)"
              >
                <div class="col gap-2 cursor-pointer">
                  <h6 class="semi line-clamp-2" title="{{ wp.name }}">
                    {{ wp.name }}
                  </h6>
                  <caption>
                    {{
                      wp.createdAtDisplay
                    }}
                  </caption>
                </div> </a
              >}

              <div class="absolute top-1/2 mt-[-20px] left-[-40px] z-[1]">
                <button class="btn-icon outlined" (click)="previousPage(workspacesScroll)">
                  <i class="icon-ChevronLeft"></i>
                </button>
              </div>
              <div class="absolute top-1/2 mt-[-20px] right-[-40px] z-[1]">
                <button class="btn-icon outlined" (click)="nextPage(workspacesScroll)">
                  <i class="icon-ChevronRight"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col gap-4">
          <span class="h7 semi">Ghi chú</span>
          <notes-group />
          <notes-group />
          <notes-group />
        </div> -->
      </div>
      <div class="fixed w-full h-screen top-0 left-0 on-screen" (click)="searchFocus = false"></div>
      }
    </div>
  </div>
  <div class="h-full flex overflow-auto" [ngClass]="{ 'mt-[-212px]': searchFocus }">
    <div
      class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:flex flex-wrap gap-4 px-4 lg:px-10 w-full lg:max-w-[1040px] xl:max-w-[1240px] mx-auto content-start justify-start"
    >
      <button class="workspace-item new" (click)="dialog.showModal(); isInitialized = false">
        <div class="icon"><i class="icon-PlusCircle"></i></div>
        <span class="name">Không gian mới</span>
      </button>
      @for(workspace of workspaces;track workspace.id;let i = $index ){
      <div
        class="relative"
        (mouseover)="onMouseHover($event, workspace.id)"
        (mouseleave)="onMouseHover($event, workspace.id)"
      >
        <button
          class="hidden link small absolute right-0 top-0"
          [ngClass]="{ 'btn-icon': hover[workspace.id] }"
          (click)="editWorkspace(workspace)"
        >
          <i class="icon-Edit"></i>
        </button>
        <a class="workspace-item" [routerLink]="paths.workspaceDetails.path.replace(':id', workspace.id)">
          <div class="col gap-2">
            <h6 class="semi line-clamp-2" [title]="workspace.name">
              {{ workspace.name }}
            </h6>
            <caption>
              {{
                workspace.createdAtDisplay
              }}
            </caption>
          </div>
        </a>
      </div>
      }
    </div>
  </div>
</div>
<dialog #dialog (click)="dialog.close()">
  <div class="col w-full items-stretch" (click)="$event.stopPropagation()">
    <button class="btn-icon link self-end" (click)="dialog.close()"><i class="icon-XClose"></i></button>
    <div class="col gap-6 p-6">
      <div class="col gap-2">
        <h6 class="semi">Mục tiêu bạn tạo không gian này là...</h6>
        <input
          [(ngModel)]="workspaceGoal"
          type="text"
          placeholder="Ghi lại mục tiêu của bạn...."
          class="w-full"
          (keyup.enter)="summarizeGoal()"
        />
      </div>
      @if(!isInitialized){
      <button class="btn secondary" [disabled]="workspaceGoal == '' || creating" (click)="summarizeGoal()">
        {{ isInitializing ? 'Đang xử lý' : 'Khởi tạo!' }}</button
      >} @else {
      <div class="col gap-6">
        <div class="col gap-2">
          <h6 class="semi">Tên không gian</h6>
          <input
            [(ngModel)]="workspaceName"
            type="text"
            placeholder="Ghi lại mục tiêu của bạn...."
            class="w-full"
            [ngClass]="{ shine: isInitializing }"
          />
        </div>
        <div class="col gap-2">
          <h6 class="semi">Dùng ngôn ngữ</h6>
          <select [(ngModel)]="language" (change)="updateLanguage($event)" [ngClass]="{ shine: isInitializing }">
            <option [value]="LangKey.VI">Tiếng Việt</option>
            <option [value]="LangKey.EN">Tiếng Anh</option>
          </select>
        </div>
      </div>
      <button
        class="btn secondary"
        [disabled]="workspaceGoal == '' || workspaceName == '' || creating"
        (click)="createWorkspace()"
      >
        {{ creating ? 'Đang xử lý' : 'Tôi chắc chắn với mục tiêu này!' }}</button
      >}
    </div>
  </div>
</dialog>
<dialog #errorDialog (click)="errorDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6" (click)="$event.stopPropagation()">
    <button class="btn-icon link self-end" (click)="editDialog.close()"><i class="icon-XClose"></i></button>
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn orange" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>
<mat-menu #optionMenu xPosition="before" yPosition="above" class="h-[250px] w-[250px]">
  <button class="btn link">Chỉnh sửa</button>
</mat-menu>
<dialog #editDialog (click)="editDialog.close()">
  <div class="col w-full items-stretch" (click)="$event.stopPropagation()">
    <button class="btn-icon link self-end" (click)="editDialog.close()"><i class="icon-XClose"></i></button>
    <workspace-edit
      [workspace]="selectedWorkspace"
      (closeOnEditEvent)="onEdited($event)"
      (closeOnDeleteEvent)="onDeleted($event)"
    ></workspace-edit>
  </div>
</dialog>

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { chatServerApi } from "@data/chat/chat.service";
import { DBHelper } from "@data/helper/helper";
import { IWorkspaceService } from "@domain/workspace/i-workspace-service";
import { environment } from "@environments";
import { Content } from "@share-utils/data";
import { IGeneralChatService } from "@share-utils/domain";
import { QueryDocumentSnapshot, WithFieldValue } from 'firebase/firestore';
import { catchError, from, map, Observable, of } from "rxjs";
import { LangKey, Note, Source, Workspace } from "./workspace-models";

const workspaceServerApi = environment.workspaceApi;
// const workspaceServerApi = 'http://127.0.0.1:5001/kyonsvn-dev/asia-east1/workspaceApi';
const WORKSPACE_LANGUAGE_KEY = "workspaceLanguage";

@Injectable({
  providedIn: 'root',
})
class WorkspaceService implements IWorkspaceService, IGeneralChatService {
  http = inject(HttpClient);
  db = inject(Firestore);

  clearSuggestions(userId: string, workspaceId: string): Observable<unknown> {
    return this.http.put(`${workspaceServerApi}/clearSuggestions`, { userId, workspaceId });
  }
  getSuggestions(userId: string, workspaceId: string): Observable<string[]> {
    return this.http.post(`${workspaceServerApi}/suggestions`, { userId, workspaceId }).pipe(
      map((res: any) => {
        if (res.data === undefined || res.data.length === 0) return [];
        return res.data;
      })
    );
  }

  createWorkspace(userId: string, goal: string, name: string, language: string): Observable<string> {
    const params = {
      userId,
      goal,
      name,
      language
    }
    return this.http.post(`${workspaceServerApi}/create`, params).pipe(
      map((res: any) => {
        if (res.data === undefined) return '';
        return res.data;
      })
    );
  }

  getWorkspaces(userId: string): Observable<Workspace[]> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get(`${workspaceServerApi}/get`, { params: params }).pipe(
      map((res: any) => {
        if (res.data === undefined) return [];
        return (res.data as any[]).map((dataObject) => Workspace.fromJson(dataObject));
      })
    );
  }

  getWorkspace(userId: string, id: string): Observable<Workspace> {
    const params = new HttpParams().set('userId', userId).set('id', id);
    return this.http.get(`${workspaceServerApi}/get`, { params: params }).pipe(
      map((res: any) => {
        if (res.data === undefined) return Workspace.empty();
        return Workspace.fromJson(res.data);
      })
    );
  }

  editWorkspace(userId: string, workspaceId: string, newData: { name?: string, goal?: string, onlySource?: boolean, language?: string }): Observable<any> {
    const params = {
      userId, workspaceId, newData
    }
    return this.http.put(`${workspaceServerApi}/update`, params).pipe(
      map((res: any) => {
        if (res.data) return res.data;
        return false;
      })
    );
  }

  deleteWorkspace(userId: string, workspaceId: string): Observable<boolean> {
    const params = new HttpParams().set('userId', userId).set('workspaceId', workspaceId);

    return this.http.delete(`${workspaceServerApi}/delete`, { params: params }).pipe(
      map((res: any) => {
        if (res.status == 200) return true;
        return false;
      })
    );
  }

  generalSeach(userId: string, query: string): Observable<Note[]> {
    const params = new HttpParams();
    params.set('userId', userId);
    params.set('query', query);
    return this.http.get(`${workspaceServerApi}/generalSeach`, { params: params }).pipe(
      map((res: any) => {
        if (res.data === undefined) return [];
        return (res.data as any[]).map((dataObject: any) => Note.fromJson(dataObject));
      })
    )
  }

  createSources(userId: string, workspaceId: string, files: { fileId: string, fileName: string, publicUrl: string, extension: string }[]): Observable<boolean> {
    const params = {
      userId,
      workspaceId,
      files
    }
    const timeoutPromise = from(new Promise<boolean>(resolve => setTimeout(() => resolve(true), 10000)));
    const headers = new HttpHeaders({ timeout: `${60 * 1000 * 5}` });
    // const request = this.http.post(`${workspaceServerApi}/test`, params).pipe(
    // ))
    // return this.http.post(`${workspaceServerApi}/test`, params, { headers: new HttpHeaders({ timeout: `${60 * 1000 * 5}` }) }).pipe(
    const request = this.http.post(`${workspaceServerApi}/createSource`, params, { headers }).pipe(
      map((res: any) => {
        // res = data;
        if (res.data == undefined) return false;
        return true;
      }),
      catchError(error => { // Handle errors from the HTTP request
        console.error('Error creating sources:', error);
        return of(false); // Return false for error handling
      })
    );
    // return concat(timeoutPromise, request);
    return request;
  }

  getSources(userId: string, workspaceId: string): Observable<Source[]> {
    const params = new HttpParams().set('userId', userId).set('workspaceId', workspaceId);
    return this.http.get(`${workspaceServerApi}/sources`, { params: params }).pipe(
      map((res: any) => {
        // res = data;
        if (res.data === undefined) return [];
        return (res.data as any[]).map((dataObject: any) => Source.fromJson(dataObject));
      })
    );
  }

  selectSource(userId: string, workspaceId: string, sourceId: string, isChecked: boolean) {
    const params = {
      userId,
      workspaceId,
      sourceId,
      isChecked
    }
    return this.http.post(`${workspaceServerApi}/selectSource`, params);
  }


  sendMessage(userId: string, message: string, model: string, { chatId }: { chatId?: string } = {}): Observable<string> {
    // const formData = new FormData();
    // formData.append('prompt', message);
    // formData.append('userId', userId);
    // if (chatId) formData.append('chatId', chatId);
    const params = {
      userId,
      chatId,
      prompt: message
    }
    const headers = new HttpHeaders({ timeout: `${60 * 1000 * 5}` });
    // return new Observable();
    return this.http.post(`${workspaceServerApi}/ask`, params, { headers }).pipe(
      map((res: any) => {
        if (res['chatId'] === undefined) return '';
        return res['chatId'];
      })
    );
  }

  getMessages(userId: string, chatId: string): Observable<Content[]> {
    return this.http.get(`${chatServerApi}/user/${userId}/chat/${chatId}`).pipe(
      catchError(DBHelper.handleError('GET getMana', [])),
      map((res: any) => {
        if (res.data === undefined) return [];
        return (res.data as any[]).map((data: any) => Content.parseContent(data));
      })
    );
  }

  setWorkspaceLanguage(lang: LangKey): void {
    window.localStorage.setItem(WORKSPACE_LANGUAGE_KEY, lang);
  }
  getWorkspaceLanguage(): LangKey {
    return (window.localStorage.getItem(WORKSPACE_LANGUAGE_KEY) as LangKey) || LangKey.VI;
  }

  summarizeGoal(userId: string, workspaceGoal: string): Observable<{ summary: string, language: LangKey }> {
    const params = {
      userId,
      objective: workspaceGoal
    }
    return this.http.post(`${workspaceServerApi}/summarize`, params).pipe(
      map((res: any) => {
        if (res.data === undefined || res.data.summary == undefined || res.data.language == undefined) return { summary: '', language: LangKey.VI };
        return res.data;
      })
    );
  }

  createWorkspaceChat(userId: string, workspaceId: string) {
    return this.http.post(`${workspaceServerApi}/createWorkspaceChat`, { userId, workspaceId });
  }

  removeSourceFromWorkspace(userId: string, workspaceId: string, sourceId: string): Observable<unknown> {
    return this.http.post(`${workspaceServerApi}/removeSource`, { userId, workspaceId, sourceId });
  }

  streamSourceStatus(userId: string, sourceId: string): Observable<string> {
    // const params = new HttpParams().set('userId', userId).set('sourceId', sourceId);
    // return this.http.get(`${workspaceServerApi}/getSourceStatus`, { params: params }).pipe(
    //   map((res: any) => {
    //     if (res.data != undefined && typeof res.data == 'string') return res.data
    //     return '';
    //   })
    // );
    const docRef = doc(this.db, `users/${userId}/sources/${sourceId}`).withConverter(sourceConverter);
    return docData(docRef).pipe(
      map((data: any) => {
        if (data.status != undefined && typeof data.status == 'string') {
          return data.status
        }
        return '';
      })
    );
  }

}

const sourceConverter = {
  toFirestore(value: WithFieldValue<any>) {
    return { value };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot) {
    return { id: snapshot.id, ...snapshot.data() };
  },
};

export { WorkspaceService };


<div class="bg-gradient flex-1 h-full flex flex-col md:flex-row overflow-auto">
  <div class="col md:hidden">
    <div class="col p-4 gap-3">
      <h5>Lối tắt</h5>
      <button
        class="btn-extra-large flex justify-between w-full bg-blue-gradient text-white"
        [disabled]="learnings.length === 0"
        (click)="goToLastestLearningGoal()"
      >
        <span>Tiếp tục học bài trước đó</span>
        <i class="icon-ArrowForward"></i>
      </button>
    </div>
  </div>
  <div class="col items-stretch flex-1 md:overflow-y-auto">
    <div class="hidden md:flex flex-col">
      <!-- <div class="flex justify-between">
        <img src="/assets/images/logo-h.svg" alt="" width="126px" />
        <div class="flex gap-2">
          <a class="menu-item active">
            <img src="/assets/icons/Home.svg" alt="" />
            <span>Trang chủ</span>
          </a>
          <a [routerLink]="paths.chatbot.path" class="menu-item">
            <img src="/assets/images/FAQ.svg" alt="" />
            <span>Chat với Kyo</span>
          </a>
          <a [routerLink]="paths.account.path" class="menu-item">
            <img src="/assets/icons/Profile.svg" alt="" />
            <span>Tài khoản</span>
          </a>
        </div>
      </div> -->
    </div>
    <div class="col gap-6 md:gap-8 py-4">
      <div class="col gap-4 px-4 md:px-8">
        <div class="flex justify-between">
          <h5 class="hidden md:block">Chỉ số của bạn</h5>
          <span class="h7 md:hidden">Chỉ số của bạn</span>
          <!-- <a (click)="showWhatIsStats = true" class="!text-blueGray-500 !no-underline flex cursor-pointer gap-0.5">
            <i class="icon-Help"></i>
            <span class="underline">Những chỉ số này là gì?</span>
          </a> -->
        </div>
        <div class="stats text-white items-center shadow-4 rounded-2xl md:h-[296px] flex flex-col md:flex-row">
          <div class="flex-1 flex items-center justify-center p-3">
            <img src="/assets/images/Spider chart.svg" alt="" class="w-[179px] md:w-auto" />
          </div>
          <div class="h-[150px] border-r border-solid border-blueGray-600 hidden md:block"></div>
          <div class="w-[222px] border-t border-solid border-blueGray-600 md:hidden"></div>
          <div class="flex-1 h-full col gap-6 md:gap-0 items-center justify-start p-14 pt-6 md:pt-14">
            <div class="flex gap-4">
              <img
                width="32px"
                height="32px"
                *ngFor="let o of over; index as i"
                [src]="'/assets/images/' + (o || activeStat === i ? stats[i] : statsBW[i]) + '.svg'"
                alt=""
                (mouseover)="over[i] = true"
                (mouseout)="over[i] = false"
                class="cursor-pointer stat"
                [ngClass]="{ active: activeStat === i }"
                (click)="activeStat = i"
              />
            </div>
            <div class="flex-1 text-base font-bold flex items-center text-center">
              Tính năng bí mật sắp<br />được bật mí 🥳
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoading; else loading" class="col gap-4 px-4 md:px-8">
        <div class="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-4 lg:gap-0">
          <div class="flex flex-col lg:flex-row gap-3 lg:items-center">
            <div class="flex gap-4 items-center">
              <h5 class="hidden md:block">Mục tiêu gần đây</h5>
              <span class="h7 md:hidden">Mục tiêu gần đây</span>
              <!-- <a href="">Xem tất cả Mục tiêu học tập</a> -->
            </div>
            <a (click)="showAll = !showAll">{{ !showAll ? 'Xem tất cả' : 'Xem thu gọn' }} Mục tiêu học tập</a>
          </div>
          <button
            #btnCreateLearningGoal
            [routerLink]="paths.mockTest.path"
            class="btn orange large"
            data-tooltip-content="Bấm vào đây để tạo mục tiêu học tập mới"
          >
            <i class="icon-Add"></i>Tạo mục tiêu học tập mới
          </button>
        </div>
        <div
          *ngIf="learnings.length === 0; else elseBlock"
          class="bg-mess col items-center gap-2 p-4 md:p-8 mx-[-14px] md:relative"
        >
          <img class="md:hidden" src="/assets/images/pointer-xs.webp" alt="" />
          <img class="hidden md:block absolute top-[10px] right-[122px]" src="/assets/images/pointer.webp" alt="" />
          <h5 class="text-orange">Bạn chưa có Mục tiêu học tập!</h5>
          <p class="max-w-[435px] text-center">
            Kyons cần biết mục tiêu học tập để tạo ra bài tập vừa với trình độ của bạn. Bấm vào nút này để Tạo mục tiêu
            học tập đầu tiên nhé!
          </p>
        </div>
        <ng-template #elseBlock>
          <div
            class="grid grid-col-1 xl:grid-cols-2 gap-x-6 gap-y-4 max-h-[80vh] overflow-y-auto pb-8 px-[16px] mx-[-14px]"
          >
            <div
              (click)="selectLearningGoal(item)"
              *ngFor="let item of learnings | slice : 0 : (showAll ? 1000 : 4)"
              class="learning-goal-item"
            >
              <img src="/assets/images/Subject.svg" alt="" />
              <div class="col gap-0.5 flex-1">
                <h6 class="hidden md:block">{{ item.name }}</h6>
                <span class="h8 md:hidden">{{ item.name }}</span>
                <span>{{ item.program.name }}</span>
              </div>
              <circle-progress
                [percent]="item.progress"
                [title]="item.progress + '%'"
                [animateTitle]="false"
                [showSubtitle]="false"
                [radius]="32"
                titleFontSize="16px"
                titleFontWeight="bold"
                outerStrokeColor="var(--color-lightOrange-1)"
                [lazy]="false"
              ></circle-progress>
            </div>
          </div>
        </ng-template>
        <kyonsvn-tutorial
          *ngIf="showTutorial"
          (skipCallback)="skip()"
          [scriptElements]="[btnCreateLearningGoal]"
          [scriptEvents]="[['click', script]]"
        ></kyonsvn-tutorial>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <student-loading></student-loading>
  </ng-template>
  <div *ngIf="!isLoading" class="hidden md:flex hideOnShowSidebarRight h-full w-[436px]">
    <div class="col p-11 bg-rocket text-white relative w-[436px]">
      <div class="flex-1"></div>
      <div class="col gap-4">
        <h5>Lối tắt</h5>
        <button
          class="btn-extra-large flex justify-between w-full bg-blue-gradient"
          [disabled]="learnings.length === 0"
          (click)="goToLastestLearningGoal()"
        >
          <span>Tiếp tục học bài trước đó</span>
          <i class="icon-ArrowForward"></i>
        </button>
        <button
          class="btn-extra-large flex justify-between w-full bg-orange-gradient"
          [routerLink]="paths.mockTest.path"
        >
          <span>Tạo “Bài kiểm tra thử thông minh” mới</span>
          <i class="icon-ArrowForward"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showWhatIsStats"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px]">
    <h4>Chỉ số của bạn</h4>
    <span>Text</span>
    <button (click)="showWhatIsStats = false" class="btn orange w-full">Đã hiểu</button>
  </div>
</div>

<div class="col gap-4 w-full on-screen p-6">
  <h5>Đổi mật khẩu</h5>
  <form class="w-full col gap-4" [formGroup]="passwordForm" autocomplete="off">
    <div class="flex flex-col gap-1 flex-1">
      <span>Mật khẩu cũ</span>
      <div class="relative w-full">
        <input
          #oldPasswordElm
          [type]="showOldPassword ? 'text' : 'password'"
          class="w-full"
          formControlName="current_password"
          autocomplete="false"
        />
        <button
          type="button"
          (click)="showOldPassword = !showOldPassword"
          class="absolute right-0 top-0 w-11 h-full flex items-center justify-center cursor-pointer"
        >
          <i *ngIf="showOldPassword" class="icon-VisibilityOff"></i>
          <i *ngIf="!showOldPassword" class="icon-Visibility"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-col gap-1 flex-1">
      <span>Mật khẩu mới</span>
      <div class="relative w-full">
        <input
          #passwordElm
          [type]="showPassword ? 'text' : 'password'"
          class="w-full"
          formControlName="password"
          autocomplete="false"
        />
        <button
          type="button"
          (click)="showPassword = !showPassword"
          class="absolute right-0 top-0 w-11 h-full flex items-center justify-center cursor-pointer"
        >
          <i *ngIf="showPassword" class="icon-VisibilityOff"></i>
          <i *ngIf="!showPassword" class="icon-Visibility"></i>
        </button>
      </div>
      <div class="text-sm">
        Mật khẩu phải có:<br />
        <ul class="list-disc">
          <li
            class="ml-5"
            [ngClass]="{
              'text-red-0': (newPass.dirty || newPass.touched) && newPass.value.length < 8,
              'text-darkEmerald': (newPass.dirty || newPass.touched) && newPass.value.length >= 8
            }"
          >
            <i
              *ngIf="(newPass.dirty || newPass.touched) && newPass.value.length < 8"
              class="text-red-0 icon-Cancel small"
            ></i>
            <i
              *ngIf="(newPass.dirty || newPass.touched) && newPass.value.length >= 8"
              class="text-darkEmerald icon-Check small"
            ></i>
            &nbsp;Từ 8 ký tự trở lên
          </li>
          <li
            class="ml-5"
            [ngClass]="{
              'text-red-0': (newPass.dirty || newPass.touched) && notHaveUppercase(newPass.value),
              'text-darkEmerald': (newPass.dirty || newPass.touched) && !notHaveUppercase(newPass.value)
            }"
          >
            <i
              *ngIf="(newPass.dirty || newPass.touched) && notHaveUppercase(newPass.value)"
              class="text-red-0 icon-Cancel small"
            ></i>
            <i
              *ngIf="(newPass.dirty || newPass.touched) && !notHaveUppercase(newPass.value)"
              class="text-darkEmerald icon-Check small"
            ></i>
            &nbsp;Ký tự viết hoa (A-Z)
          </li>
          <li
            class="ml-5"
            [ngClass]="{
              'text-red-0': (newPass.dirty || newPass.touched) && notHaveDigit(newPass.value),
              'text-darkEmerald': (newPass.dirty || newPass.touched) && !notHaveDigit(newPass.value)
            }"
          >
            <i
              *ngIf="(newPass.dirty || newPass.touched) && notHaveDigit(newPass.value)"
              class="text-red-0 icon-Cancel small"
            ></i>
            <i
              *ngIf="(newPass.dirty || newPass.touched) && !notHaveDigit(newPass.value)"
              class="text-darkEmerald icon-Check small"
            ></i>
            &nbsp;Ký tự số (0-9)
          </li>
          <li
            class="ml-5"
            [ngClass]="{
              'text-red-0': (newPass.dirty || newPass.touched) && notHaveSpecial(newPass.value),
              'text-darkEmerald': (newPass.dirty || newPass.touched) && !notHaveSpecial(newPass.value)
            }"
          >
            <i
              *ngIf="(newPass.dirty || newPass.touched) && notHaveSpecial(newPass.value)"
              class="text-red-0 icon-Cancel small"
            ></i>
            <i
              *ngIf="(newPass.dirty || newPass.touched) && !notHaveSpecial(newPass.value)"
              class="text-darkEmerald icon-Check small"
            ></i>
            &nbsp;Ký tự đặc biệt (~! &#64;#$%^&*_-+=`|\()&#123;&#125;[]:;"'&#60;>,.?/)
          </li>
        </ul>
      </div>
    </div>
    <hr />
  </form>
  <button
    class="btn orange small self-end"
    [ngClass]="{ shine: processing }"
    (click)="updatePassword()"
    [disabled]="processing"
  >
    {{ processing ? 'Đang xử lý' : 'Cập nhật' }}
  </button>
</div>
<dialog #errorDialog (click)="errorDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn orange" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>

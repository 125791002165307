<div *ngIf="show" class="w-full flex text-base items-center justify-center relative">
  <div class="w-full flex relative px-4 py-2 xl:px-6 xl:py-8 items-center gap-2">
    @if(showLogo){
    <a *ngIf="theme === 'default'" href="/"><img src="/assets/images/logo-h.svg" alt="" /></a>
    <a *ngIf="theme === 'universe'" href="/"><img src="/assets/images/logo-horizontal-light.svg" alt="" /></a>
    }
    <div class="flex-1"></div>
    <ul class="flex items-center gap-4">
      <li>
        <a
          class="menu-item home"
          [routerLink]="'/'"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img src="/assets/icons/Home.svg" alt="" />
          <span>Trang chủ</span>
        </a>
      </li>
      <li>
        <a class="menu-item" [routerLink]="paths.chatbot.path" routerLinkActive="active">
          <img src="/assets/images/FAQ.svg" alt="" />
          <span class="hidden md:flex">Chat với Kyo</span>
        </a>
      </li>
      <li>
        <a class="menu-item" [routerLink]="paths.task.path" routerLinkActive="active">
          <img src="/assets/icons/Mission.svg" alt="" />
          <span class="hidden md:flex">Nhiệm vụ</span>
        </a>
      </li>
      <li>
        <a class="menu-item" [routerLink]="paths.account.path" routerLinkActive="active">
          <img src="/assets/icons/Profile.svg" alt="" />
          <span class="hidden md:flex">Tài khoản</span>
        </a>
      </li>
    </ul>
    <!-- <div class="w-2"></div> -->
    <button class="btn-icon link" [matMenuTriggerFor]="themeSelectionMenu" title="Giao diện">
      <i class="icon-Theme text-blueGray-400 text-[24px]" (click)="showSettings = !showSettings"></i>
    </button>
    <!-- <div
      *ngIf="showSettings"
      class="absolute z-20 right-0 top-full rounded-lg p-4"
      [ngClass]="{ 'bg-white': theme === 'default', 'bg-black': theme === 'universe' }"
    >
      <h5>Giao diện</h5>
      <div class="flex gap-2">
        <button class="btn orange" (click)="setTheme('default')">Mặc định</button>
        <button class="btn !bg-teal-0 !border-teal-0" (click)="setTheme('universe')">Universe</button>
      </div>
    </div> -->
  </div>
</div>
<mat-menu #themeSelectionMenu yPosition="below" xPosition="before">
  <button mat-menu-item (click)="setTheme('default')">Mặc định</button>
  <button mat-menu-item (click)="setTheme('universe')">Universe</button>
</mat-menu>

<div class="p-6 md:p-11 w-full h-full col md:items-center md:justify-center">
  <div class="w-full flex md:hidden justify-end">
    <a (click)="back()" class="icon-btn"><i class="icon-XClose"></i></a>
  </div>
  <div *ngIf="hasError === ''" class="flex flex-col w-full md:w-[434px] gap-4">
    <h2 class="mb-0 hidden md:block">Chọn chương trình học</h2>
    <h4 class="mb-0 md:hidden">Chọn chương trình học</h4>
    <div class="rounded-lg p-6 flex flex-col gap-6 bg-white items-start">
      <div class="flex flex-col gap-4 w-full">
        <select
          #turorial1
          data-tooltip-content="Hãy chọn môn học mà bạn muốn"
          class="form-control w-full"
          [(ngModel)]="selectedSubject"
          (ngModelChange)="changeSubject()"
        >
          <option [ngValue]="null" disabled selected>Chọn môn</option>
          <option *ngFor="let subject of subjects" [ngValue]="subject">
            {{ subject.name }}
          </option>
        </select>
        <select
          #turorial2
          data-tooltip-content="Chọn tiếp chương trình học"
          class="form-control w-full"
          [attr.disabled]="this.selectedSubject === null ? 'disabled' : null"
          [(ngModel)]="selectedProgram"
          (ngModelChange)="changeProgram()"
        >
          <option [ngValue]="emptyProgram" disabled selected>Chọn chương trình</option>
          <option *ngFor="let program of programs" [ngValue]="program">
            {{ program.name }}
          </option>
        </select>
        <select
          #turorial3
          data-tooltip-content="Chọn thêm mục tiêu học tập của bạn"
          class="form-control w-full"
          [(ngModel)]="selectedLearningGoal"
          [attr.disabled]="this.selectedProgram.id === '' ? 'disabled' : null"
        >
          <option [ngValue]="emptyLearningGoal" disabled selected>Mục tiêu học tập</option>
          <ng-container *ngFor="let learningGoal of learningGoals">
            <option [ngValue]="learningGoal">{{ learningGoal.name }}</option>
          </ng-container>
        </select>
      </div>
      <button
        #turorial4
        data-tooltip-content="Bấm vào đây để chọn chủ đề cần học"
        (click)="start()"
        class="btn orange w-full"
        [attr.disabled]="selectedLearningGoal === emptyLearningGoal || isLoading ? 'disabled' : null"
      >
        {{ isLoading ? 'Đang xử lý' : 'Bắt đầu' }}
      </button>
      <a [routerLink]="paths.home.path" class="hidden md:block">Về trang chủ</a>
      <kyonsvn-tutorial
        *ngIf="showTutorial"
        (skipCallback)="skip()"
        [scriptElements]="[turorial1, turorial2, turorial3, turorial4]"
        [scriptEvents]="[
          ['change', script1],
          ['change', script2],
          ['change', script3],
          ['click', script4]
        ]"
      ></kyonsvn-tutorial>
    </div>
  </div>
  <!-- <div
    *ngIf="hasError === 'RanOutMockTest'"
    class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-blueGray-100 p-6 md:m-0"
  >
    <div class="w-full md:w-[400px] bg-white rounded-lg p-8 flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <div class="h7">Bạn đã sử dụng hết bài kiểm tra!</div>
        <span>Để tạo mục tiêu mới, bạn cần mua thêm gói dịch vụ Kyons.</span>
      </div>
      <div class="flex justify-between">
        <button (click)="hasError = ''" class="outlined">Không đổi mục tiêu</button>
        <a class="btn orange" [routerLink]="paths.package.path">Mua gói</a>
      </div>
    </div>
    <div class="h-1/3"></div>
  </div> -->
  <!-- <div
    class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-blueGray-100 p-6 md:m-0"
    [ngClass]="{ hidden: !showMockTestTemplate, flex: showMockTestTemplate }"
  >
    <div class="w-full md:w-[700px] bg-white rounded-lg p-8 flex flex-col gap-8">
      <div class="flex flex-col gap-2">
        <img src="/assets/images/Robot 2.svg" alt="" class="w-[150px] h-[150px] self-center" />
        <h5>
          Bạn đã chọn mục tiêu <b class="text-orange">{{ selectedLearningGoal.name }}</b> trong chương trình
          <b class="text-orange">{{ selectedProgram.name }}</b>
        </h5>
        <span
          >Tiếp theo, bạn sẽ cần hoàn thành bài kiểm tra có
          <b class="text-orange">{{ selectedLearningGoal.totalQuestions }}</b> câu câu hỏi trong vòng
          <b class="text-orange">{{ selectedLearningGoal.duration }}</b> phút. Để bài học sát với năng lực của bạn nhất,
          hãy làm bài kiểm tra này hết sức và tránh bị gián đoạn bạn nhé!</span
        >
        <span>Bạn hãy chọn giúp Kyons một đề kiểm tra để tiếp tục</span>
        <select
          #turorial5
          data-tooltip-content="Chọn bộ đề bạn muốn kiểm tra thử"
          class="form-control w-full"
          [(ngModel)]="selectedMockTestTemplate"
          [disabled]="this.selectedProgram.id === ''"
        >
          <option [ngValue]="emptyMockTestTemplate" disabled selected>Chọn đề kiểm tra</option>
          <ng-container *ngFor="let learningGoal of mockTestTemplates">
            <option [ngValue]="learningGoal">{{ learningGoal.name }}</option>
          </ng-container>
        </select>
      </div>
      <div class="flex flex-col md:flex-row justify-between gap-4">
        <button (click)="showMockTestTemplate = false" class="outlined order-2 md:order-1">
          Để mình suy nghĩ lại
        </button>
        <button
          #turorial6
          data-tooltip-content="Bắt đầu làm bài kiểm tra"
          class="btn order-1 md:order-2"
          [disabled]="selectedMockTestTemplate === emptyMockTestTemplate || isLoading"
          (click)="submit()"
        >
          Bắt đầu thôi
        </button>
      </div>
    </div>
    <div class="h-1/3"></div>
  </div> -->
  <div
    *ngIf="hasError === 'ExistentUnsubmittedMockTest'"
    class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center bg-blueGray-100 p-6 md:m-0"
  >
    <div class="w-full md:w-[400px] bg-white rounded-lg p-8 flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <div class="h7">Chưa hoàn tất bài kiểm tra thử thông minh</div>
        <span>Bạn có một bào kiểm tra thử thông minh chưa hoàn tất, vui lòng vào lộ trình học để tiếp tục làm bài</span>
      </div>
      <div class="w-full">
        <a
          class="btn orange"
          [routerLink]="paths.learningPath.path"
          fragment="mockTestAnchor"
          [queryParams]="{ active_tab: 1 }"
          >Đến lộ trình học</a
        >
      </div>
    </div>
    <div class="h-1/3"></div>
  </div>
</div>

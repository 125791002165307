<div class="flex flex-col w-full h-full">
  <div class="flex flex-col gap-6 h-full overflow-hidden">
    <div class="flex gap-4 w-full">
      <div class="prefix-icon w-full md:w-auto">
        <i class="icon-Search absolute pointer-events-none"></i>
        <input
          type="text"
          placeholder="Tìm kiếm..."
          [(ngModel)]="searchFile"
          (ngModelChange)="onSearchChat()"
          class="w-full"
        />
      </div>
      @if(multiple){
      <button class="btn orange outlined" (click)="onSelectFiles()" [disabled]="selection.selected.length === 0">
        Chọn {{ selection.selected.length }} tài liệu
      </button>
      }
      <span class="flex-1"></span>
      <button class="btn-icon outlined onClose" (click)="onClose.emit()">
        <i class="icon-XClose"></i>
      </button>
    </div>
    <div class="w-full h-full flex flex-col gap-3 overflow-hidden">
      <div class="table-responsive">
        <mat-table
          id="fileTable"
          [dataSource]="dataSource"
          matSort
          matSortStart="desc"
          class="w-full min-w-[700px] h-fit"
        >
          @if(multiple){<ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef class="basis-auto grow-0 p-0">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="basis-auto grow-0 p-0">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </mat-cell> </ng-container
          >}
          <!-- Name Definition -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="" class="min-w-[30%] shrink-0">
              <span class="font-bold">Tên tài liệu</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="flex gap-4 min-w-[30%] shrink-0">
              @if(row.isImage){
              <img [src]="row.fileUri" class="w-[48px] h-[48px] object-cover" />
              }@else if(row.isPdf){
              <mat-icon class="text-blueGray-500">picture_as_pdf</mat-icon>
              }@else if(row.isAudio){
              <mat-icon class="text-blueGray-500">audio_file</mat-icon>
              }@else if(row.isVideo){
              <mat-icon class="text-blueGray-500">video_file</mat-icon>
              }@else {
              <mat-icon class="text-blueGray-500">description</mat-icon>
              }
              <span
                ><span class="line-clamp-1" title="{{ row.name }}">{{ row.name.split('.')[0] }}</span
                >.{{ row.name.split('.')[1] }}</span
              >
            </mat-cell>
          </ng-container>
          <!-- Type Definition -->
          <ng-container matColumnDef="mimeType">
            <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="">
              <span class="font-bold">Loại tài liệu</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="text-center">{{ row.typeDisplay }}</span>
            </mat-cell>
          </ng-container>
          <!-- Size Definition -->
          <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="">
              <span class="font-bold">Kích thước</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="text-center">{{ row.sizeDisplay }}</span>
            </mat-cell>
          </ng-container>
          <!-- Type Definition -->
          <ng-container matColumnDef="createdAt">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription=""
              class="justify-end"
              start="desc"
            >
              <span class="font-bold">Ngày tạo</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="justify-end">
              <span class="text-center">{{ row.dateDisplay }}</span>
            </mat-cell>
          </ng-container>
          <!-- Header and Row Declarations -->
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="!multiple && fileClicked.emit(row)"
            [ngClass]="{ 'cursor-pointer': !multiple }"
          ></mat-row>
          <!-- <mat-row>
            <mat-cell colspan="4" *ngIf="searchFile != '' && dataSource.filteredData.length == 0"
              >Không có tập tin nào giống với tìm kiếm {{ searchFile }}</mat-cell
            >
          </mat-row> --> </mat-table
        >@if(loading) {
        <div class="col w-full h-full overflow-hidden absolute">
          <div class="bg-blueGray-200 h-[56px] w-full shrink-0"></div>
          <div class="col">
            <div class="shine w-full h-[81px] border-b border-[#0000001f]"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-1"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-2"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-3"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-4"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f]"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-1"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-2"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-3"></div>
            <div class="shine w-full h-[81px] border-b border-[#0000001f] delay-4"></div>
          </div>
        </div>
        }
      </div>
      <mat-paginator [pageSizeOptions]="[10]" aria-label="Select page of periodic elements"> </mat-paginator>
    </div>
  </div>
</div>

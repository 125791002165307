<!-- <div class="hidden md:flex w-full justify-center">
  <student-top-menu class="xl:max-w-[1122px] w-full" />
</div>
<div class="md:hidden flex justify-between w-full p-4 md:p-0 bg-white">
  <button class="text-orange flex gap-2 items-center w-10 h-10 text-nowrap" [routerLink]="paths.home.path">
    <i
      class="text-2xl w-6 h-6 icon-ChevronLeft"
      [ngClass]="{ 'icon-ChevronLeft': isSmMenuHide(), 'icon-Home': !isSmMenuHide() }"
    ></i>
    <span *ngIf="!isSmMenuHide()" class="font-semibold">Trang chủ</span>
  </button>
  <button class="text-orange min-w-10 h-10 items-center flex justify-end" (click)="toggleMenu()">
    <i class="text-2xl w-6 h-6" [ngClass]="{ 'icon-MenuHamburger': isSmMenuHide(), 'icon-XClose': !isSmMenuHide() }"></i>
  </button>
</div> -->
<div class="flex flex-1 md:h-full justify-center w-full kyons-bg">
  <div class="flex flex-col w-full xl:max-w-[1122px] gap-6 p-0 pt-4 md:p-4 xl:p-6">
    <div class="flex flex-col md:flex-row gap-6 items-start h-full">
      <div
        class="flex flex-col justify-stretch md:w-[320px] w-full h-[calc(100vh_-_106px)] md:h-auto gap-6 fixed md:relative bg-blueGray-100 md:bg-transparent p-4 md:p-0 shrink-0"
        [ngClass]="{ 'sm-menu-hide': isSmMenuHide() }"
      >
        <div class="flex flex-col on-screen rounded-md p-0 md:p-6 gap-6">
          <h4 class="text-center hidden md:flex">Tài khoản</h4>
          <ul class="flex flex-col gap-2 w-full font-semibold">
            <li class="account-item" [ngClass]="{ 'buttoncard-bg-default': activeTab === 0 }">
              <a
                (click)="activeTab = 0"
                class="flex justify-between items-center"
                [ngClass]="{ 'text-lightBlue-1': activeTab === 0 }"
              >
                <span class="flex items-center gap-2"><i class="icon-Test"></i> Tài khoản và thanh toán</span>
                <i class="icon-ChevronRight text-[20px]"></i
              ></a>
            </li>
            <li class="account-item" [ngClass]="{ 'buttoncard-bg-default': activeTab === 1 }">
              <a
                (click)="activeTab = 1"
                class="flex justify-between items-center"
                [ngClass]="{ 'text-lightBlue-1': activeTab === 1 }"
              >
                <span class="flex items-center gap-2"><i class="icon-Test"></i> Thông tin cá nhân</span>
                <i class="icon-ChevronRight text-[20px]"></i
              ></a>
            </li>
            <li class="account-item" [ngClass]="{ 'buttoncard-bg-default': activeTab === 2, }">
              <a
                (click)="activeTab = 2"
                class="flex justify-between items-center"
                [ngClass]="{ 'text-lightBlue-1': activeTab === 2 }"
              >
                <span class="flex items-center gap-2"><i class="icon-Lock"></i> Đổi mật khẩu</span>
                <i class="icon-ChevronRight"></i>
              </a>
            </li>
            <li class="border-b border-blueGray-300"></li>
            <li class="account-item text-red-0">
              <a
                class="flex items-center gap-2 no-underline"
                [routerLink]="paths.signOut.path"
                routerLinkActive="router-link-active"
                ><i class="icon-SignOut"></i> Đăng xuất</a
              >
            </li>
          </ul>
        </div>
      </div>
      @if (activeTab === 0){
      <student-account-and-payment />
      } @else if (activeTab === 1){
      <student-profile />
      } @else if (activeTab === 2){
      <student-change-password />
      }
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FileController } from '@app/file.controller';
import { ManaController } from '@app/mana.controller';
import { TaskAnimationController } from '@app/task-animation.controller';
import { NavigationService } from '@data/navigation/navigation.service';
import { ThemeService } from '@data/theme/theme.service';
import { environment } from '@environments';
import { SkinType, ThemeType } from '@share-utils/view';

@Component({
  selector: 'top-right-menu',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './top-right-menu.component.html',
  styleUrl: './top-right-menu.component.scss',
})
export class TopRightMenuComponent implements OnInit, AfterViewInit {
  taskAnimationController = inject(TaskAnimationController);
  paths = inject(NavigationService).paths;
  manaController = inject(ManaController);
  fileController = inject(FileController);
  themeService = inject(ThemeService);
  ThemeType = ThemeType;
  SkinType = SkinType;

  capacity = this.fileController.capacity$;

  mana = this.manaController.mana$;
  coin = this.taskAnimationController.coinDisplay$;
  dialogText!: string;
  isCollapsed = false;
  isShowMobileMenu = false;
  environment = environment;

  @ViewChild('dialog') dialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('coinInTopMenuPosition') coinInTopMenuPosition!: ElementRef;

  @Input() backToKyons = false;

  @Output('collapseEvent') collapseEvent = new EventEmitter<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateCoinPosition();
  }
  ngOnInit() {
    this.manaController.refresh();
    this.taskAnimationController.refreshCoinValue();
    this.fileController.refresh();
  }

  ngAfterViewInit(): void {
    this.updateCoinPosition();
  }

  private updateCoinPosition() {
    this.taskAnimationController.coinInTopMenuPosition = {
      x: this.coinInTopMenuPosition.nativeElement.offsetLeft + 12,
      y: this.coinInTopMenuPosition.nativeElement.offsetTop + 12
    };
  }

  showDialog(text: string) {
    this.dialogText = text;
    this.dialogElm.nativeElement.showModal();
  }

  collapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }

  setSkin(skin: SkinType): void {
    this.themeService.setSkin(skin);
  }

  setTheme(theme: ThemeType): void {
    this.themeService.setTheme(theme);
  }
}

<div class="xl:flex-row w-full xl:h-full col relative">
  <div class="p-6 absolute w-full top-[68px] sm:hidden">
    <div class="col w-full bg-white rounded-2xl p-4 gap-4 h-[264px]">
      <div class="flex justify-between gap-1">
        <div class="flex-1 tab-btn" [ngClass]="{ active: activeTab === 0 }" (click)="activeTab = 0">Lộ trình học</div>
        <div class="flex-1 tab-btn" [ngClass]="{ active: activeTab === 1 }" (click)="activeTab = 1">
          Chi tiết tiến độ
        </div>
      </div>
      <div class="col gap-4 w-full">
        <span>Đây là lộ trình học tập để bạn đạt mục tiêu:</span>
        <div class="col gap-2 justify-center w-full items-center">
          <img src="/assets/images/subject.webp" alt="" width="80" height="80" />
          <h6 class="text-orange">{{ selectedStudentLearningGoal.name }}</h6>
          <b>{{ selectedStudentLearningGoal.program.name }}</b>
        </div>
      </div>
    </div>
  </div>
  <div class="gap-2 flex xl:hidden justify-center">
    <!-- <a [routerLink]="paths.home.path" class="menu-item">
      <img src="/assets/icons/Home.svg" alt="" />
      <span>Trang chủ</span>
    </a>
    <a [routerLink]="paths.chatbot.path" class="menu-item">
      <img src="/assets/images/FAQ.svg" alt="" />
      <span>Chat với Kyo</span>
    </a>
    <a [routerLink]="paths.account.path" class="menu-item">
      <img src="/assets/icons/Profile.svg" alt="" />
      <span>Tài khoản</span>
    </a> -->
    <!-- <student-top-menu [showLogo]="false" /> -->
  </div>
  <div class="col flex-1 gap-6 items-center py-16 px-8 bg-image" [ngClass]="{ 'xs-hidden': activeTab !== 0 }">
    <div class="block sm:hidden h-[264px]"></div>
    <div class="hidden sm:flex gap-6 p-6 bg-white rounded-2xl shadow-3 w-[520px]">
      <img src="/assets/images/Subject.svg" alt="" />
      <div class="col gap-2">
        <span>Đây là lộ trình học tập để bạn đạt mục tiêu:</span>
        <h5 class="text-orange">{{ selectedStudentLearningGoal.name }}</h5>
        <span class="h7">{{ selectedStudentLearningGoal.program.name }}</span>
      </div>
    </div>
    <div *ngIf="!learingPathError; else learingPathErrorTemplate" class="w-full flex items-start justify-center">
      <div
        *ngIf="lessons.length !== 0 || isLoadingLessons; else learingPathEmptyTemplate"
        class="w-full flex items-start justify-center"
      >
        <div
          *ngIf="lessons.length !== 0 && !showActivateLearningPathBtn"
          #widgetsWrapper
          class="w-[328px] md:w-[760px]"
        >
          <div
            #scrollBottomElm
            class="hidden md:flex w-full hide-scrollbar flex-col gap-[50px] h-[calc(100vh_-_280px)] overflow-y-auto px-[56px] py-8"
          >
            <div
              *ngFor="let block of lessonBlockOf3OnMDScreen; index as bi"
              class="hidden md:flex gap-[24px]"
              [ngClass]="{
                even: bi % 2 === 0,
                odd: bi % 2 !== 0
              }"
            >
              <ng-template ngFor let-i [ngForOf]="blockOf3">
                <ng-template [ngIf]="i + bi * 3 < lessons.length">
                  <!-- <div
                    *ngIf="bi % 2 !== 0 && i !== 0 && lessons[i + bi * 3 + 1] !== undefined"
                    class="absolute path"
                  ></div> -->
                  <div *ngIf="i !== 0" class="absolute path"></div>
                  <button
                    [id]="'lesson-' + lessons[i + bi * 3].id"
                    data-tooltip-content="Bạn hãy bấm vào đây để làm bài tập thực hành theo lộ trình học"
                    data-tooltip-position="bottom"
                    *ngIf="lessons[i + bi * 3].name !== ''"
                    class="lesson-block"
                    [ngClass]="{
                      'complete-block': !lessons[i + bi * 3].isNew,
                      'new-block': lessons[i + bi * 3].isNew
                    }"
                    (click)="onLessonBlockClick(lessons[i + bi * 3])"
                    tabindex="0"
                  >
                    <div class="h7 line-clamp-3">{{ lessons[i + bi * 3].name }}</div>
                    <span *ngIf="!lessons[i + bi * 3].isNew" class="h-7 w-full text-right italic font-bold"
                      >Hoàn tất</span
                    >
                    <div class="flex w-full justify-end">
                      <circle-progress
                        *ngIf="lessons[i + bi * 3].isNew"
                        [percent]="lessons[i + bi * 3].progress!"
                        [title]="lessons[i + bi * 3].progress! + '%'"
                        [showSubtitle]="false"
                        [radius]="32"
                        titleFontSize="16px"
                        titleFontWeight="bold"
                        outerStrokeColor="var(--color-white)"
                        titleColor="var(--color-white)"
                        innerStrokeColor="var(--color-blueGray-300)"
                      ></circle-progress>
                    </div>
                  </button>
                  <!-- <div class="absolute path"></div> -->
                </ng-template>
                <div *ngIf="i + bi * 3 === lessons.length && i !== 0" class="absolute path last"></div>
                <button
                  *ngIf="i + bi * 3 === lessons.length"
                  class="lesson-block"
                  [ngClass]="{
                    'waiting-block': !learningPath.isCompleted,
                    'finish-block': learningPath.isCompleted
                  }"
                  (click)="onLastLessonBlockClick()"
                  tabindex="0"
                >
                  <span *ngIf="learningPath.isCompleted" class="h7 w-full text-center !mt-[-10px]">Tới cuối đường</span>
                  <span *ngIf="!learningPath.isCompleted" class="h7">(Đang chờ được khám phá...)</span>
                  <div class="flex w-full justify-center relative">
                    <i class="icon-Lock text-5xl mt-[-74px] absolute"></i>
                  </div>
                </button>
              </ng-template>
            </div>
          </div>
          <div
            #scrollBottomXSElm
            class="md:hidden w-full hide-scrollbar flex flex-col gap-[38px] flex-shrink-0 h-[70vh] max-h-[500px] overflow-y-auto px-[28px] py-8"
          >
            <div
              *ngFor="let block of lessonBlockOf2OnXSScreen; index as bi"
              class="flex md:hidden gap-[12px]"
              [ngClass]="{
                even: bi % 2 === 0,
                odd: bi % 2 !== 0
              }"
            >
              <ng-template ngFor let-i [ngForOf]="blockOf2">
                <ng-template [ngIf]="i + bi * 2 < lessons.length">
                  <!-- <div
                    *ngIf="bi % 2 !== 0 && i !== 0 && lessons[i + bi * 3 + 1] !== undefined"
                    class="absolute path"
                  ></div> -->
                  <div *ngIf="i !== 0" class="absolute path"></div>
                  <button
                    [id]="'lessonXs-' + lessons[i + bi * 2].id"
                    data-tooltip-content="Bạn hãy bấm vào đây để làm bài tập thực hành theo lộ trình học"
                    data-tooltip-position="bottom"
                    *ngIf="lessons[i + bi * 2].name !== ''"
                    class="lesson-block"
                    [ngClass]="{
                      'complete-block': !lessons[i + bi * 2].isNew,
                      'new-block': lessons[i + bi * 2].isNew
                    }"
                    (click)="onLessonBlockClick(lessons[i + bi * 2])"
                    [title]="lessons[i + bi * 2].name"
                  >
                    <div class="text-[12px] md:h7 text-ellipsis line-clamp-2 font-bold">
                      {{ lessons[i + bi * 2].name }}
                    </div>
                    <span
                      *ngIf="!lessons[i + bi * 2].isNew"
                      class="text-[12px] md:h7 w-full text-right italic font-bold"
                      >Hoàn tất</span
                    >
                    <div *ngIf="lessons[i + bi * 2].isNew" class="flex w-full justify-end">
                      <circle-progress
                        *ngIf="lessons[i + bi * 2].isNew"
                        [percent]="lessons[i + bi * 2].progress!"
                        [title]="lessons[i + bi * 2].progress! + '%'"
                        [showSubtitle]="false"
                        [radius]="20"
                        titleFontSize="12px"
                        titleFontWeight="bold"
                        outerStrokeColor="var(--color-white)"
                        titleColor="var(--color-white)"
                        innerStrokeColor="var(--color-blueGray-300)"
                        innerStrokeWidth="4"
                        [outerStrokeWidth]="4"
                        [space]="-4"
                        [lazy]="false"
                      ></circle-progress>
                    </div>
                  </button>
                  <!-- <div class="absolute path"></div> -->
                </ng-template>
                <div *ngIf="i + bi * 2 === lessons.length && i !== 0" class="absolute path last"></div>
                <button
                  *ngIf="i + bi * 2 === lessons.length"
                  class="lesson-block"
                  [ngClass]="{
                    'waiting-block': !learningPath.isCompleted,
                    'finish-block finish-blockXs': learningPath.isCompleted
                  }"
                  (click)="onLastLessonBlockClick()"
                  tabindex="0"
                >
                  <b *ngIf="learningPath.isCompleted" class="text-[12px] md:text-[16px] w-full text-center !mt-[-10px]"
                    >Tới cuối đường</b
                  >
                  <b *ngIf="!learningPath.isCompleted" class="text-[12px] md:text-[16px]"
                    >(Đang chờ được khám phá...)</b
                  >
                  <div *ngIf="!learningPath.isCompleted" class="flex w-full justify-center relative">
                    <i class="icon-Lock text-5xl mt-[-44px] absolute"></i>
                  </div>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="showActivateLearningPathBtn" class="">
          <div class="col w-[520px] h-[500px] px-8 items-center gap-2 text-center justify-center">
            <h5 class="text-white">Bạn chưa tạo lộ trình học!</h5>
            <h6 class="h6 text-white">bấm vào nút bên dưới để bắt đầu học thôi</h6>
            <button (click)="activateLearningPath()" class="btn orange">Tạo lộ trình học</button>
          </div>
        </div> -->
      </div>
      <ng-template #learingPathEmptyTemplate>
        <div class="col w-full max-w-[520px] h-[500px] px-8 items-center gap-2 text-center justify-center">
          <h5 class="text-blueGray-500">Meo meo meo meo, trả lại tâm trí tôi đây...</h5>
          <h6 class="h6 text-white">Bạn đợi một chút để AI của Kyons trả lại lộ trình học tập cho bạn nhé ><</h6>
        </div>
      </ng-template>
    </div>
    <ng-template #learingPathErrorTemplate>
      <div class="col w-full max-w-[520px] h-[500px] px-8 items-center gap-2 text-center justify-center">
        <h5 class="text-blueGray-500">Meo meo meo meo, trả lại tâm trí tôi đây...</h5>
        <h6 class="h6 text-white">Bạn đợi một chút để AI của Kyons trả lại lộ trình học tập cho bạn nhé ><</h6>
      </div>
    </ng-template>
  </div>
  <div
    class="col items-stretch md:self-center md:w-[614px] p-6 md:p-8 xl:h-full overflow-y-auto gap-8"
    [ngClass]="{ 'xs-hidden': activeTab !== 1 }"
  >
    <div class="block sm:hidden h-[264px]"></div>
    <!-- <div class="justify-end hidden xl:flex">
      <student-top-menu [showLogo]="false" />
    </div> -->
    <div class="col gap-4" id="mockTestAnchor">
      <div class="flex justify-between items-center">
        <h5 class="hidden md:block">Theo dõi quá trình học</h5>
        <div class="h7 md:hidden">Theo dõi quá trình học</div>
        <a (click)="showWhatIsProbabilityIndex = true" class="!no-underline flex cursor-pointer gap-0.5 items-center">
          <i class="icon-Help"></i>
          <span class="underline">Đây là gì?</span>
        </a>
      </div>
      <div class="flex justify-center items-center bg-white rounded-2xl p-6">
        <div class="w-[132px] md:w-[198px] aspect-square">
          <circle-progress
            *ngIf="!learningPath.isEmpty"
            [percent]="learningPath.progress"
            title="Tiến độ học"
            [titleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
            titleFontSize="14px"
            titleColor="var(--color-primaryBlue)"
            [subtitle]="learningPath.progress + '%'"
            [subtitleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
            subtitleFontSize="30px"
            subtitleFontWeight="bold"
            subtitleColor="var(--color-primaryBlue)"
            titleFontWeight="bold"
            [radius]="80"
            outerStrokeColor="var(--color-lightOrange-1)"
            innerStrokeColor="var(--color-blueGray-300)"
            [outerStrokeWidth]="24"
            innerStrokeWidth="24"
            [space]="-24"
            [responsive]="true"
            [lazy]="false"
          ></circle-progress>
          <circle-progress
            *ngIf="learningPath.isEmpty"
            [percent]="0"
            title="Tiến độ học"
            [titleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
            titleFontSize="14px"
            titleColor="var(--color-primaryBlue)"
            [subtitle]="'--'"
            [subtitleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
            subtitleFontSize="30px"
            subtitleFontWeight="bold"
            subtitleColor="var(--color-primaryBlue)"
            titleFontWeight="bold"
            [radius]="80"
            outerStrokeColor="var(--color-blueGray-300)"
            innerStrokeColor="var(--color-blueGray-300)"
            [outerStrokeWidth]="24"
            innerStrokeWidth="24"
            [space]="-24"
            [responsive]="true"
          ></circle-progress>
        </div>
        <!-- <div class="w-0 h-[156px] border border-r-blueGray-300"></div>
        <div class="w-[132px] md:w-[198px] aspect-square">
          <circle-progress
            *ngIf="!probabilityIndex"
            [percent]="0"
            title="Tỉ lệ thành công"
            titleFontSize="14px"
            titleColor="var(--color-primaryBlue)"
            [subtitle]="'--'"
            subtitleFontSize="30px"
            subtitleFontWeight="bold"
            subtitleColor="var(--color-primaryBlue)"
            titleFontWeight="bold"
            [radius]="80"
            outerStrokeColor="var(--color-blueGray-300)"
            innerStrokeColor="var(--color-blueGray-300)"
            [outerStrokeWidth]="24"
            innerStrokeWidth="24"
            [space]="-24"
            [responsive]="true"
          ></circle-progress>
          <circle-progress
            *ngIf="probabilityIndex"
            [percent]="probabilityIndex"
            title="Tỉ lệ thành công"
            titleFontSize="14px"
            titleColor="var(--color-primaryBlue)"
            [subtitle]="probabilityIndex + '%'"
            subtitleFontSize="30px"
            subtitleFontWeight="bold"
            subtitleColor="var(--color-primaryBlue)"
            titleFontWeight="bold"
            [radius]="80"
            outerStrokeColor="var(--color-lightBlue-1)"
            innerStrokeColor="var(--color-blueGray-300)"
            [outerStrokeWidth]="24"
            innerStrokeWidth="24"
            [space]="-24"
            [responsive]="true"
            [lazy]="false"
          ></circle-progress>
        </div> -->
      </div>
    </div>
    <div class="col gap-4" [ngClass]="{ 'md:bg-robot': hasNewMockTest }">
      <div class="flex justify-between items-center">
        <h5 class="hidden md:block">Bài kiểm tra thử thông minh</h5>
        <span class="md:hidden h7">Bài kiểm tra thử thông minh</span>
        <a (click)="showWhatIsMockTest = true" class="!no-underline flex cursor-pointer gap-0.5 items-center">
          <i class="icon-Help"></i>
          <span class="underline">Đây là gì?</span>
        </a>
      </div>
      <div *ngIf="hasNewMockTest" class="p-6 col gap-2 bg-blue-gradient rounded-2xl shadow-4">
        <div class="flex px-4 justify-between text-white">
          <span class="caption font-bold">Bài kiểm tra chưa hoàn tất</span>
          <span class="caption font-bold">Ngày tạo</span>
        </div>
        <div class="col gap-1">
          <ng-container *ngFor="let item of mockTests">
            <div
              class="flex p-4 justify-between bg-white-gradient rounded-xl"
              *ngIf="item.status === MockTestStatus.new"
            >
              <div class="flex gap-2 items-center">
                <a (click)="goToMockTest(item)" class="!text-primaryBlue-500 cursor-pointer"
                  >Bài kiểm tra số {{ item.index! + 1 }}</a
                >
                <i class="icon-ChevronRight text-[16px]"></i>
              </div>
              <span>{{ item.getFormattedDate() }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col bg-white rounded-2xl overflow-hidden shrink-0">
      <mat-table [dataSource]="dataSource" matSort class="h-[248px]">
        <!-- Name Definition -->
        <ng-container matColumnDef="index">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="">
            <span class="caption font-bold text-blueGray-500">Tên bài kiểm tra</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="flex gap-2 items-center" *ngIf="row.status !== MockTestStatus.learning_path_activated">
              <a (click)="goToMockTest(row)" class="!text-primaryBlue-500 cursor-pointer"
                >Bài kiểm tra số {{ row.index! + 1 }}</a
              >
              <i class="icon-ChevronRight text-[16px]"></i>
            </div>
            <div class="flex gap-2 items-center" *ngIf="row.status === MockTestStatus.learning_path_activated">
              <a (click)="goToMockTest(row)" class="no-underline font-bold">Bài kiểm tra số {{ row.index! + 1 }}</a>
              <i class="icon-ChevronRight text-[16px]"></i>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Title Definition -->
        <ng-container matColumnDef="score">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="">
            <span class="caption font-bold text-blueGray-500">Điểm số</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <b class="w-[48px] text-center">{{ row.score }}</b>
          </mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="['index', 'score']; sticky: true" class="ASD"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['index', 'score']"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[4]" aria-label="Select page of periodic elements"> </mat-paginator>
      <button [routerLink]="paths.mockTest.path" class="btn orange large w-full !rounded-none">
        Tạo bài kiểm tra thử thông minh mới
      </button>
    </div>
    <div class="flex justify-center rounded-2xl shadow-3 bg-gradient pt-6 pr-6 text-white">
      <canvas
        #chartCanvas
        baseChart
        width="500"
        height="300"
        [type]="'line'"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [legend]="false"
      >
      </canvas>
    </div>
  </div>
</div>
<div
  *ngIf="showWhatIsProbabilityIndex"
  class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center z-[100]"
>
  <div
    class="absolute top-0 md:static col rounded-2xl bg-white gap-6 p-6 max-w-[700px] max-h-[calc(100vh_-_8rem)] overflow-auto"
  >
    <h4>Theo dõi quá trình học</h4>
    <p>
      Để có thể đạt được mục tiêu lớn mà bạn đặt ra trong học tập, trước tiên hệ thống của Kyons sẽ chia mục tiêu ấy
      thành <b class="text-orange">nhiều mục tiêu nhỏ</b> và cụ thể hơn ứng với từng dạng kiến thức, và sắp xếp chúng
      thành một <b class="text-orange">lộ trình học</b>.
    </p>
    <p>
      Và để đạt được những mục tiêu nhỏ ấy, bạn cần phải <b class="text-lightBlue-1">làm bài tập liên tục</b> cho đến
      khi hệ thống AI của Kyons xác định mức độ vững vàng kiến thức đó của bạn và sẽ mở khóa mục tiêu tiếp theo. Sau khi
      hoàn thành tất cả các mục tiêu nhỏ thì cũng là lúc bạn hoàn tất lộ trình học và đạt được mục tiêu lớn của mình.
    </p>
    <p>
      Để theo dõi quá trình học của bản thân, bạn có thể quan sát chỉ số <b class="text-lightBlue-1">“Tiến độ học”</b>.
      Ngoài ra, hệ thống còn có một chỉ số đặc biệt hơn đó là <b class="text-lightBlue-1">“Tỉ lệ thành công”</b>, thể
      hiện khả năng bạn có thể hoàn thành mục tiêu lớn dựa trên số lượng kiến thức mà bạn đã học so với toàn bộ kiến
      thức có thể ra trong bài kiểm tra.
    </p>
    <button (click)="showWhatIsProbabilityIndex = false" class="btn orange w-full shrink-0">Đã hiểu</button>
  </div>
</div>
<div
  *ngIf="showCompleteTutorial"
  class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center z-[100]"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 max-w-[700px] max-h-[calc(100vh_-_8rem)] overflow-auto">
    <h4>Và thế là hết... Bây giờ bạn đã biết cách sử dụng Kyons App rồi đó!</h4>
    <p>
      Nếu quên, bạn có thể truy cập phần <a (click)="completeTutorial(false)">Trang chủ</a> >
      <i class="icon-Settings text-[16px]"></i> > “Hướng dẫn sử dụng” để xem lại. Chúc bạn học tập thật vui vẻ nha
    </p>
    <button (click)="completeTutorial()" class="btn orange w-full shrink-0">Quay về trang chủ</button>
  </div>
</div>
<div
  *ngIf="showWhatIsMockTest"
  class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center z-[100]"
>
  <div
    class="absolute top-0 md:static col rounded-2xl bg-white gap-6 p-6 max-w-[700px] max-h-[calc(100vh_-_8rem)] overflow-auto"
  >
    <h4>Bài kiểm tra thử thông minh</h4>
    <p>
      <b class="text-orange">Bài kiểm tra thử thông minh</b> (Smart Mock Test) do công nghệ AI của Kyons tạo ra nhằm mục
      đích đánh giá khả năng đạt được mục tiêu học tập của bạn. Từ đó, hệ thống sẽ thiết kế lộ trình học tập dành riêng
      cho bạn để tập trung cải thiện những kiến thức còn yếu.
    </p>
    <p>
      Với mỗi mục tiêu học tập, bạn sẽ có thể tạo <b class="text-orange">không giới hạn</b> số lượng bài kiểm tra thử
      thông minh!
    </p>
    <p>
      Bởi vì kết quả mỗi bài kiểm tra được tạo ra sẽ khác nhau nên mỗi bài kiểm tra sẽ có một lộ trình học tương ứng.
      Bạn chỉ cần chọn <b class="text-lightBlue-1">một lộ trình mà bạn nghĩ rằng sát với khả năng của mình nhất</b> để
      bắt đầu, bằng cách nhấn vào tên bài kiểm tra.
    </p>
    <p>
      Lưu ý: Sau khi chọn một lộ trình học, bạn hoàn toàn có thể
      <b class="text-lightBlue-1">thay đổi sang lộ trình học khác</b>. Vì thế, hãy cứ trải nghiệm Kyons để hiểu rõ kiến
      thức mình đang còn yếu nằm ở đâu nhé! Chúc bạn học tập thật tốt nha.
    </p>
    <button (click)="showWhatIsMockTest = false" class="btn orange w-full shrink-0">Đã hiểu</button>
  </div>
</div>
<div *ngIf="showTutorial">
  <kyonsvn-tutorial
    *ngIf="tutorialPart === 1"
    (skipCallback)="skip()"
    [scriptElements]="scriptElements"
    [scriptEvents]="[['click', script1]]"
    class="hidden md:block"
  ></kyonsvn-tutorial>
  <kyonsvn-tutorial
    *ngIf="tutorialPart === 1"
    (skipCallback)="skip()"
    [scriptElements]="scriptXsElements"
    [scriptEvents]="[['click', script1]]"
    class="md:hidden"
  ></kyonsvn-tutorial>
  <kyonsvn-tutorial
    *ngIf="tutorialPart === 2"
    (skipCallback)="skip()"
    [scriptElements]="scriptElements"
    [scriptEvents]="[['click', script2]]"
    class="hidden md:block"
  ></kyonsvn-tutorial>
  <kyonsvn-tutorial
    *ngIf="tutorialPart === 2"
    (skipCallback)="skip()"
    [scriptElements]="scriptXsElements"
    [scriptEvents]="[['click', script2]]"
    class="md:hidden"
  ></kyonsvn-tutorial>
</div>

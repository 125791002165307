import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, HostBinding, HostListener, Injector, OnDestroy, OnInit, Renderer2, effect, inject, runInInjectionContext } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChatController } from '@app/chat.controller';
import { NavigationService } from '@data/navigation/navigation.service';
import { ThemeService } from '@data/theme/theme.service';
import { UserService } from '@data/user/user.service';
import { environment } from '@environments';
import { cancel } from '@share-utils/utils';
import { SkinType, ThemeType } from '@share-utils/view';
import { ChatbotComponent } from '@view/pages/chatbot/chatbot.component';
import { AiMenuComponent } from '@view/share-components/ai-menu/ai-menu.component';
import { MenuComponent } from '@view/share-components/menu/menu.component';
import { TrackingComponent } from '@view/share-components/tracking/tracking.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './layout-default.component.html',
  imports: [CommonModule, RouterModule, MenuComponent, TrackingComponent, ChatbotComponent, AiMenuComponent],
})
export class LayoutDefaultComponent implements OnInit, OnDestroy {
  themeService = inject(ThemeService);
  theme = this.themeService.themeStore();
  skin = this.themeService.skinStore();
  injector = inject(Injector);
  router = inject(Router);
  route = inject(ActivatedRoute);
  document = inject(DOCUMENT);
  renderer = inject(Renderer2);
  paths = inject(NavigationService).paths;
  chatController = inject(ChatController);
  userService = inject(UserService);

  subscription!: Subscription;
  isCollapsed = false;
  showKyo = false;
  showChat = this.chatController.showChat;
  isCompact = false;
  isMobile = false;

  @HostBinding('class') class = 'app-full app-bg';

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isMobile = width < 1024;
  }

  ngOnInit(): void {
    this.userService.updateCurrentUser();
    if (this.route.snapshot.queryParamMap.get('compact') === 'true') {
      this.isCompact = true;
    }
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.skin = environment.name != 'STUDENT' ? SkinType.default : this.themeService.skinStore();
        this.theme = environment.name == 'STUDENT' ? ThemeType.light : this.themeService.themeStore();
        this.renderer.setAttribute(this.document.body, 'data-theme', this.theme);
        this.renderer.setAttribute(this.document.body, 'data-skin', this.skin);
      });
    });
    // this.subscription = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //   } else if (event instanceof NavigationEnd) {
    //     // Your logic to handle route changes here
    //     this.renderer.setAttribute(this.document.body, 'data-skin', this.skin);
    //     if (event.url.includes(this.paths.chatbot.path) || event.url.includes(this.paths.mockTest.path)) {
    //       this.showKyo = false;
    //     }
    //     else {
    //       this.showKyo = true;
    //     }
    //   }
    // });

    if (this.router.url.includes(this.paths.chatbot.path) || this.router.url.includes(this.paths.mockTest.path)) {
      this.showKyo = false;
    }
    else {
      this.showKyo = true;
    }
    this.isMobile = window.innerWidth < 1170;
  }

  ngOnDestroy() {
    cancel([this.subscription]);
  }

  onCollapse($event: boolean) {
    this.isCollapsed = $event;
  }

  openChat() {
    this.chatController.toggleKyo(true);
  }

  closeChat() {
    this.chatController.toggleKyo(false);
  }

  openChatNewTab() {
    window.open(this.paths.chatbot.path, '_blank');
  }

}

import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Injector, OnDestroy, OnInit, Renderer2, effect, inject, runInInjectionContext } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { ThemeService } from '@data/theme/theme.service';
import { UserService } from '@data/user/user.service';
import { environment } from '@environments';
import { cancel } from '@share-utils/utils';
import { SkinType, ThemeType } from '@share-utils/view';
import { TrackingComponent } from '@view/share-components/tracking/tracking.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  template: '<student-tracking style="display: contents"></student-tracking><router-outlet></router-outlet>',
  imports: [RouterModule, TrackingComponent],
})
export class LayoutFullComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'app-full';

  themeService = inject(ThemeService);
  skin = this.themeService.getSkin();
  theme = this.themeService.getTheme();
  injector = inject(Injector);
  router = inject(Router);
  document = inject(DOCUMENT);
  renderer = inject(Renderer2);
  subscription!: Subscription;
  userService = inject(UserService);

  ngOnInit(): void {
    this.userService.updateCurrentUser();
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.skin = environment.name != 'STUDENT' ? SkinType.default : this.themeService.skinStore();
        this.theme = environment.name == 'STUDENT' ? ThemeType.light : this.themeService.themeStore();
        this.renderer.setAttribute(this.document.body, 'data-theme', this.theme);
        this.renderer.setAttribute(this.document.body, 'data-skin', this.skin);
      });
    });
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Do something before navigation starts (optional)
      } else if (event instanceof NavigationEnd) {
        // Your logic to handle route changes here
        this.renderer.setAttribute(this.document.body, 'data-theme', this.theme);
        this.renderer.setAttribute(this.document.body, 'data-skin', this.skin);
      }
    });
  }

  ngOnDestroy() {
    cancel([this.subscription]);
  }
}

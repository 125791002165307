<!-- <div class="flex-col md:hidden justify-stretch">
  <student-top-menu [showLogo]="false" />
</div> -->
<div *ngIf="testResult" class="flex w-full h-full justify-center overflow-auto">
  <div class="col gap-6 md:gap-8 w-full max-w-[1122px] p-6 md:p-12 h-fit">
    <div class="flex items-center justify-between w-full">
      <div class="flex gap-4 items-center">
        <h4>Điểm bài kiểm tra</h4>
      </div>
      <!-- <div class="gap-2 hidden md:flex">
        <student-top-menu [showLogo]="false" />
      </div> -->
    </div>
    <div class="col p-6 bg-white rounded-lg gap-6">
      <div class="col md:flex-row items-center justify-center w-full gap-8">
        <div class="flex items-center justify-between flex-shrink flex-grow-0 gap-6 md:gap-8">
          <div class="h-[132px] lg:w-[198px] aspect-square">
            <circle-progress
              [percent]="testResult.score * 10"
              [subtitle]="testResult.score.toString() + '/10'"
              [animateTitle]="false"
              [lazy]="false"
              title="Điểm số"
              [titleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              titleFontSize="12px"
              titleFontWeight="bold"
              subtitleFontSize="30px"
              subtitleFontWeight="bold"
              [subtitleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              [radius]="75"
              outerStrokeColor="var(--color-lightOrange-1)"
              innerStrokeColor="var(--color-blueGray-300)"
              [innerStrokeWidth]="20"
              [outerStrokeWidth]="20"
              [space]="-20"
              [responsive]="true"
            ></circle-progress>
          </div>
          <!-- <div class="h-[132px] lg:w-[198px] aspect-square">
            <circle-progress
              *ngIf="!probabilityIndex"
              [percent]="0"
              [subtitle]="'--'"
              [animateTitle]="false"
              [lazy]="false"
              title="Tỉ lệ thành công"
              [titleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              titleFontSize="12px"
              titleFontWeight="bold"
              subtitleFontSize="30px"
              subtitleFontWeight="bold"
              [subtitleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              [radius]="75"
              outerStrokeColor="var(--color-blueGray-300)"
              innerStrokeColor="var(--color-blueGray-300)"
              [innerStrokeWidth]="20"
              [outerStrokeWidth]="20"
              [space]="-20"
              [responsive]="true"
              [lazy]="false"
            ></circle-progress>
            <circle-progress
              *ngIf="probabilityIndex"
              [percent]="probabilityIndex"
              [subtitle]="probabilityIndex + '%'"
              [animateTitle]="false"
              [lazy]="false"
              title="Tỉ lệ thành công"
              [titleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              titleFontSize="12px"
              titleFontWeight="bold"
              subtitleFontSize="30px"
              subtitleFontWeight="bold"
              [subtitleColor]="theme === 'default' ? 'var(--color-primaryBlue)' : 'var(--color-white)'"
              [radius]="75"
              outerStrokeColor="var(--color-lightBlue-1)"
              innerStrokeColor="var(--color-blueGray-300)"
              [innerStrokeWidth]="20"
              [outerStrokeWidth]="20"
              [space]="-20"
              [responsive]="true"
            ></circle-progress>
          </div> -->
        </div>
        <div class="w-full md:w-[411px] col gap-6">
          <span
            ><strong class="text-orange">Bạn muốn cải thiện điểm số?</strong><br />AI của Kyons sẽ tạo lộ trình học dựa
            vào những điểm thiếu sót của bạn.<br />Bấm <strong>Tạo lộ trình học</strong> để bắt đầu ngay!</span
          >
          <div class="col md:flex-row gap-2 md:items-center items-stretch justify-between w-full">
            <button
              #reviewElm
              data-tooltip-content="Bạn hãy bấm vào đây để xem lại kết quả bài kiểm tra của mình"
              data-tooltip-position="bottom"
              (click)="review()"
              class="btn orange outlined order-2 md:order-1"
            >
              Xem lại đáp án bài kiểm tra
            </button>
            <button
              #createElm
              data-tooltip-content="Bạn hãy bấm vào đây để tạo lộ trình học"
              data-tooltip-position="bottom"
              [disabled]="testResult.status !== MockTestStatus.mock_test_submitted && !showTutorial"
              (click)="activateLearningPath()"
              class="btn orange order-1 md:order-2"
            >
              Tạo lộ trình học
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="stats text-white items-center shadow-4 rounded-2xl md:h-[296px] flex flex-col md:flex-row">
      <div class="flex-1 flex items-center justify-center p-3">
        <img src="/assets/images/Spider chart.svg" alt="" class="w-[179px] md:w-auto" />
      </div>
      <div class="h-[150px] border-r border-solid border-blueGray-600 hidden md:block"></div>
      <div class="w-[222px] border-t border-solid border-blueGray-600 md:hidden"></div>
      <div class="flex-1 h-full col gap-6 md:gap-0 items-center justify-start p-14 pt-6 md:pt-14">
        <div class="flex gap-4">
          <img
            width="32px"
            height="32px"
            *ngFor="let o of over; index as i"
            [src]="'/assets/images/' + (o || activeStat === i ? stats[i] : statsBW[i]) + '.svg'"
            alt=""
            (mouseover)="over[i] = true"
            (mouseout)="over[i] = false"
            class="cursor-pointer stat"
            [ngClass]="{ active: activeStat === i }"
            (click)="activeStat = i"
          />
        </div>
        <div class="flex-1 text-base font-bold flex items-center text-center">
          Tính năng bí mật sắp<br />được bật mí 🥳
        </div>
      </div>
    </div>
    <!-- <div class="col md:flex-row gap-6 items-stretch md:items-center justify-between">
      <button
        [routerLink]="paths.mockTest.path"
        class="bg-white p-6 rounded-lg flex gap-6 items-center justify-between cursor-pointer flex-grow order-2 md:order-1"
      >
        <div class="col gap-2 items-start">
          <b>Bài kiểm tra quá dễ?</b>
          <span class="text-left">Bạn có thể lựa chọn lại mục tiêu học tập</span>
        </div>
        <i class="icon-btn icon-ChevronRight"></i>
      </button>
      <button
        class="bg-white p-6 rounded-lg flex gap-6 items-center justify-between cursor-pointer flex-grow order-1 md:order-2"
      >
        <div class="col gap-2 items-start">
          <b>Thách thức bạn thân nhận quà!</b>
          <span class="text-left">Nhận quà từ Kyons khi gửi bài kiểm tra cho bạn bè</span>
        </div>
        <i class="icon-btn icon-ChevronRight"></i>
      </button>
    </div> -->
  </div>
  <div *ngIf="showTutorial">
    <kyonsvn-tutorial
      *ngIf="tutorialPart === 1"
      [scriptElements]="[reviewElm]"
      [scriptEvents]="[['click', nextTutorial1], null]"
      (skipCallback)="skip()"
    ></kyonsvn-tutorial>
    <kyonsvn-tutorial
      *ngIf="tutorialPart === 2"
      [scriptElements]="[createElm]"
      [scriptEvents]="[['click', nextTutorial2], null]"
      (skipCallback)="skip()"
    ></kyonsvn-tutorial>
  </div>
</div>

<div class="body" #chatBody>
  @for (content of completeMessages; track content.createdAt; let i = $index) {
  <div class="message" [ngClass]="{ left: content.isModel, right: content.isUser }">
    <div class="avatar" *ngIf="content.isModel">
      <img src="{{ content.model?.avatar }}" alt="" />
    </div>
    <div class="content">
      @for (message of content.parts; track $index) {
      <div #box class="box" (click)="box.classList.toggle('hover')">
        <!-- {{ message | json }} - {{ message.mimeType?.includes('text/') }} -->
        @if(message.isText){
        <kyonsvn-latex [inputString]="message.text ?? ''" [inputStringId]="content.id" />
        }@else if(message.isData){
        <!-- prettier-ignore -->
        <div
          class=""
          intercepterObserver
          #intersection="intersection"
          [class.intersected]="intersection._isIntersecting"
          (isIntersecting)="isIntersecting($event, i, $index)"
          [isContinuous]="false"
        >
          @if(!message.isDeleted){
            @if(message.data!=null){
              <div class="flex gap-2">
                @if(message.data.isImage){
                  <img [src]="message.data.fileUri" />
                }
                @else{
                  @if(message.data.isPdf){
                    <mat-icon class="">picture_as_pdf</mat-icon>
                  } @else if(message.data.isAudio){
                    <mat-icon class="">audio_file</mat-icon>
                  } @else if(message.data.isVideo){
                    <mat-icon class="">video_file</mat-icon>
                  } @else {
                    <mat-icon class="">description</mat-icon>
                  }
                  <a class='flex items-center justify-center' [href]="message.data.fileUri" target="_blank"
                    ><span class="line-clamp-1">{{ message.data.name.split('.')[0] }}</span
                    >.{{ message.data.name.split('.')[1] }}</a
                  >
                }
              </div>
            }
            @if(message.url){
              <img [src]="message.url" [alt]="message.url" />
            } @else if(message.mimeType?.includes('text/')||message.mimeType == 'application/json'){
              <div class="flex justify-center items-center gap-2">
                <mat-icon class="">description</mat-icon> <i class="">{{message.name}}</i>
              </div>
              }
          }@else{<i class="">Ảnh đã bị xóa</i>}
        </div>
        @if(noManaError){
        <!-- <a class='btn lightBlue' href="/account">Nạp Mana</a> -->
        } }
        <!--  -->
        @if(i===completeMessages.length-1 && $index === content.parts.length-1 && hasPlayBtn(message) ){
        <button class="btn orange self-start" (click)="play()">Bắt đầu</button>
        <!--  -->
        }
        <!--  -->
        @if(content.isModel){
        <div class="hover-actions">
          <button class="btn-icon link small" (click)="copy(message.text!)"><i class="icon-Duplicate"></i></button>
          <button
            class="btn-icon link small like"
            (click)="like(content.id, content.like == LikeContent.LIKE ? LikeContent.NONE : LikeContent.LIKE)"
          >
            <i class="icon-ThumbsUp" [ngClass]="{ active: content.like == 1 }"></i>
          </button>
          <button
            class="btn-icon link small dislike"
            (click)="like(content.id, content.like == LikeContent.DISLIKE ? LikeContent.NONE : LikeContent.DISLIKE)"
          >
            <i class="icon-ThumbsDown" [ngClass]="{ active: content.like == -1 }"></i>
          </button>
          @if(isFullFeatures){<span class="flex-1"></span>
          <button
            class="btn-icon link small"
            title="Hiệu ứng viết chữ - {{ typingAnimation ? 'ON' : 'OFF' }}"
            (click)="typingAnimation = !typingAnimation"
            [disabled]="isThinking || isWriting"
          >
            <i class="icon-PencilLine" [ngClass]="{ 'text-blueGray-500': !typingAnimation }"></i></button
          >}
          <!-- <div class="flex gap-2">
            <button class="btn-icon link small"><i class="icon-Redo"></i></button>
            <button class="btn-icon link small"><i class="icon-Edit"></i></button>
          </div> -->
        </div>
        } @else if(content.isUser){
        <div class="hover-actions">
          <button class="btn-icon link small" (click)="copy(message.text!)"><i class="icon-Duplicate"></i></button>
          <button class="btn-icon link small" (click)="edit(content)"><i class="icon-Edit"></i></button>
          @if(isFullFeatures){<button
            class="btn-icon link small"
            (click)="remove(content); confirmDialog.showModal()"
            title="Thu hồi câu hỏi và câu trả lời"
          >
            <i class="icon-Eraser"></i>
          </button>
          <button
            class="btn-icon link small"
            (click)="undo(content); confirmDialog.showModal()"
            title="Xóa tất cả tin nhắn kể từ đây về sau"
          >
            <i class="icon-Undo"></i></button
          >}
        </div>
        }
      </div>
      }
    </div>
  </div>
  } @if(isWriting){
  <div class="left message">
    <div class="avatar">
      @for(model of interactiveModels; track model.hashTag;let i = $index){<img
        src="{{ model.avatar }}"
        class="w-[32px] h-[32px]"
      />} @empty {
      <img src="assets/images/KyoAI.svg" alt="" />
      }
    </div>
    <div class="content">
      <div class="box">
        <kyonsvn-latex [inputString]="writingText" />
      </div>
    </div>
  </div>
  } @else if(isThinking){
  <div class="left message">
    <div class="avatar relative">
      @for(model of interactiveModels; track model.hashTag;let i = $index){<img
        src="{{ model.avatar }}"
        class="w-[32px] h-[32px] absolute"
        [style.z-index]="interactiveModels.length - i"
        [style.right]="i * 8 + 'px'"
      />} @empty {
      <img src="assets/images/KyoAI.svg" alt="" />
      }
    </div>
    <div class="content">
      <div class="box">
        <span class="p-[4px] flex gap-[4px] thinking">
          <span class="bg-blueGray-400 w-[8px] aspect-square rounded-full"></span>
          <span class="bg-blueGray-400 w-[8px] aspect-square rounded-full"></span>
          <span class="bg-blueGray-400 w-[8px] aspect-square rounded-full"></span>
        </span>
      </div>
    </div>
  </div>
  } @else if(reviewMessage){
  <div class="right message">
    <div class="content">
      <div class="box">
        <kyonsvn-latex [inputString]="reviewMessage" />
      </div>
    </div>
  </div>
  } @if(suggestions.length>0){
  <div class="left message">
    <div class="w-full suggestions col gap-2">
      @for(suggestion of suggestions;track suggestion;let i = $index){
      <button
        class="rounded-full w-full min-h-14 px-3 py-2 gap-14 items-center justify-between"
        (click)="clickSuggestion(suggestion)"
      >
        <span class="text-left pl-4">{{ suggestion }}</span>
        <button class="btn-icon link"><i class="icon-ArrowForward"></i></button></button
      >}
    </div>
  </div>
  }
</div>
<dialog #confirmDialog (click)="confirmDialog.close()">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span>{{
      willDo == 'undo'
        ? 'Xóa tất cả tin nhắn kể từ đây về sau'
        : willDo == 'remove'
        ? 'Thu hồi câu hỏi và câu trả lời'
        : ''
    }}</span>
    <div class="flex gap-4 justify-between">
      <button class="btn red outlined" (click)="confirmDialog.close()">Suy nghĩ lại</button>
      <button class="btn red" (click)="confirm(); confirmDialog.close()">Thực hiện</button>
    </div>
  </div>
</dialog>

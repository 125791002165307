<div
  class="flex flex-col items-start justify-start w-full h-[calc(100%_-_72px)] md:h-[calc(100%_-_56px)] overflow-hidden"
>
  <div class="tab-wrapper" [ngClass]="{ orange: !isAI, lightBlue: isAI }">
    <div class="tab-item" [ngClass]="{ active: activeTab() === 0 }" (click)="activeTab.set(0); loadGeneral()">
      <i class="icon-Work"></i> Tổng quan
    </div>
    <div class="tab-item" [ngClass]="{ active: activeTab() === 1 }" (click)="activeTab.set(1); loadPackages()">
      <i class="icon-Subscription"></i> Gói tài khoản
    </div>
    <!-- <div class="tab-item" [ngClass]="{ 'on-screen text-primaryBlue': activeTab === 2 }" (click)="activeTab = 2">
      <i class="icon-Shop text-xl"></i> Cửa hàng
    </div> -->
    <div class="tab-item" [ngClass]="{ active: activeTab() === 3 }" (click)="activeTab.set(3); loadOrderHistory()">
      <i class="icon-History"></i> Lịch sử
    </div>
  </div>
  <div class="w-full md:p-0 p-4 md:h-full md:overflow-hidden overflow-auto flex flex-col">
    <div class="col md:on-screen md:rounded-tl-none rounded-lg p-0 md:p-6 md:h-full md:overflow-hidden">
      @switch(activeTab()){ @case(0){ @if(currentPackage) {
      <div class="flex flex-col gap-4 on-screen md:bg-transparent p-4 md:p-0 rounded-lg md:h-full overflow-auto">
        <h5>Tổng quan</h5>
        <div class="flex flex-col gap-6">
          <div class="flex md:flex-row flex-col gap-8">
            <div
              class="flex flex-col gap-2 md:w-[180px] p-4 md:p-0"
              [ngClass]="{
                'md:bg-transparent text-lightBlue-1 md:text-primaryBlue-500 rounded-lg': currentPackage.level > 0,
                'md:bg-transparent bg-blueGray-500 text-white md:text-primaryBlue-500 rounded-lg':
                  currentPackage.level === 0
              }"
            >
              <span class="h7">Gói tài khoản</span>
              <div class="flex gap-2 w-full items-center">
                <img [src]="currentPackage.image" alt="" class="w-8 h-8" />
                <h4 [ngClass]="{ 'md:text-lightBlue-1': currentPackage.level > 1 }">
                  {{ currentPackage.name }}
                </h4>
                <div class="flex-1"></div>
                <span class="flex md:hidden text-white cursor-pointer underline self-end" (click)="showBenefit = true"
                  >Xem quyền lợi</span
                >
              </div>
            </div>
            <div class="flex md:flex-col gap-2 flex-1 md:items-start items-center">
              <span class="h7 text-secondaryBlue-500">Hết hạn</span>
              <h4 class="md:text-2xl text-base">
                {{ remainingHours === 0 ? 'Không hết hạn' : remainingHoursDisplay }}
              </h4>
            </div>
            <a (click)="showBenefit = true" class="hidden md:flex">Xem quyền lợi</a>
          </div>
          <hr class="hidden md:flex" />
          <div class="gap-8 hidden md:flex">
            <div class="flex flex-col gap-2 md:w-[180px]">
              <span class="h7">Tài nguyên của bạn</span>
            </div>
            <div class="flex flex-col flex-1 gap-4">
              <div class="flex gap-3 items-start cursor-pointer" (click)="coinDialog.showModal()">
                <img src="/assets/images/Coins.svg" alt="" class="w-10 h-10" />
                <div class="flex flex-col flex-1 gap-0.5">
                  <strong class="text-lightBlue-1">Koins: {{ coin() }}</strong>
                  <span class="text-xs">Dùng Koins để đổi ngay các phần quà hấp dẫn.</span>
                </div>
                <!-- <i class="icon-ChevronRight text-base self-center"></i> -->
              </div>
              <hr />
              <div class="flex gap-3 items-start cursor-pointer" *ngFor="let item of items">
                <img [src]="item.image" alt="" class="w-10 h-10" />
                <div class="flex flex-col flex-1 gap-0.5">
                  <strong class="text-lightBlue-1">{{ item.name }}</strong>
                  <strong>Đang có: {{ item.quantity }}</strong>
                </div>
                <!-- <i class="icon-ChevronRight text-base self-center"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <div class="flex md:hidden flex-col gap-3 mt-4">
        <div class="flex flex-col gap-2 md:w-[180px]">
          <span class="h7">Tài nguyên của bạn</span>
        </div>
        <div class="flex flex-col gap-4 on-screen rounded-lg p-4">
          <div class="flex gap-3 items-start cursor-pointer" (click)="coinDialog.showModal()">
            <img src="/assets/images/Coins.svg" alt="" class="w-10 h-10" />
            <div class="flex flex-col flex-1 gap-0.5">
              <strong class="text-lightBlue-1">Koins: {{ coin() }}</strong>
              <span class="text-xs">Dùng Koins để đổi ngay các phần quà hấp dẫn.</span>
            </div>
            <!-- <i class="icon-ChevronRight text-base self-center"></i> -->
          </div>
          <hr />
          <div class="flex gap-3 items-start cursor-pointer" *ngFor="let item of items">
            <img [src]="item.image" alt="" class="w-10 h-10" />
            <div class="flex flex-col flex-1 gap-0.5">
              <strong class="text-lightBlue-1">{{ item.name }}</strong>
              <strong>Đang có: {{ item.quantity }}</strong>
            </div>
            <!-- <i class="icon-ChevronRight text-base self-center"></i> -->
          </div>
        </div>
      </div>
      } @case(1) {
      <div class="flex flex-col gap-4 p-4 md:p-0 on-screen md:bg-transparent md:h-full md:overflow-auto">
        <div class="flex">
          <h5>Gói tài khoản</h5>
          <div class="flex-1"></div>
          <div class="flex gap-3 items-center">
            <i class="icon-Cash text-4xl"></i>
            <strong>{{ balance.formatedValue }}</strong>
            <!-- <button class="btn-icon" [ngClass]="{ 'orange': !isAI, 'lightBlue': isAI }" (click)="showTopup = true"><i class="icon-Add"></i></button> -->
          </div>
        </div>
        <div class="h-full overflow-y-auto overflow-x-hidden">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="flex flex-col gap-4 p-6 rounded-lg border border-b-gray-300" *ngFor="let package of packages">
              <div class="flex flex-col gap-2.5 h-full">
                <span class="h7 flex items-center gap-2">
                  <span class="buttoncard-text">{{ package.packageTypeDisplay }}</span>
                  <span
                    *ngIf="package.isUsing"
                    class="text-white rounded-full uppercase px-2 py-0.5 text-xs"
                    [ngClass]="{ 'bg-orange': !isAI, 'bg-lightBlue-1': isAI }"
                    >Đang sử dụng</span
                  >
                </span>
                <div class="flex items-center">
                  <div class="flex gap-2 items-center">
                    <img [src]="package.image" alt="" class="w-8 h-8" />
                    <h6 [ngClass]="{ 'text-lightBlue-1': package.level > 0 }">{{ package.name }}</h6>
                  </div>
                  <div class="flex-1"></div>
                  <h6 *ngIf="package.price === 0">Miễn phí</h6>
                  <h6 *ngIf="package.price > 0">
                    {{ package.formattedSalePrice }}
                    <!-- <sub class="text-sm font-normal">/ {{ package.duration / 24 }} ngày</sub> -->
                  </h6>
                </div>
                <hr />
                <div class="flex flex-col">
                  <div [innerHTML]="package.description | safeHtml" class="check-list-wrapper"></div>
                </div>
                <span class="flex-1"></span>
                <button
                  *ngIf="package.level < currentPackage.level"
                  class="btn outlined"
                  [ngClass]="{ orange: !isAI, lightBlue: isAI }"
                  (click)="orderProcessStatus.set(OrderProcessStatus.warning)"
                >
                  Đổi về gói
                </button>
                <button
                  *ngIf="package.level > 0 && package.level === currentPackage.level"
                  class="btn"
                  [ngClass]="{ orange: !isAI, lightBlue: isAI }"
                  (click)="selectedPackage = package; showExtendingWithCooldown()"
                >
                  Gia hạn
                </button>
                <button
                  *ngIf="package.level > 0 && package.level > currentPackage.level"
                  class="btn"
                  [ngClass]="{ orange: !isAI, lightBlue: isAI }"
                  (click)="
                    selectedPackage = package;
                    currentPackage.level === 0 ? selectPayment(package) : (showUpgrade = true)
                  "
                >
                  Nâng cấp gói
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      } @case(3) {
      <div class="flex flex-col gap-4 p-4 md:p-0 on-screen md:bg-transparent md:h-full md:overflow-auto">
        @if(isViewOrder){
        <div class="flex flex-col gap-6">
          <div class="flex flex-col gap-4">
            <div class="flex gap-4 items-center">
              <div
                class="flex gap-2 items-center cursor-pointer"
                (click)="backToHistory()"
                [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
              >
                <i class="icon-ChevronLeft text-2xl"></i>
                <span>Quay lại</span>
              </div>
              <h5>Chi tiết giao dịch</h5>
            </div>
            <div class="">
              Tên gói: <b class="text-lightBlue-1">{{ orderDetails.orderPackage.name }}</b>
            </div>
            <div class="flex flex-col gap-3">
              <span>
                Gói bao gồm:
                <span *ngFor="let item of orderDetails.orderItems; index as i"
                  >{{ item.name }}{{ i > 0 ? ' ,' : '' }}</span
                >
              </span>
              <span>
                Thành tiền:
                {{ orderDetails.totalPriceDisplay }}
              </span>
              <span>
                Mã thanh toán:
                {{ orderDetails.code }}
                <button (click)="copy(orderDetails.code)">
                  <i class="icon-Duplicate" [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"></i>
                </button>
              </span>
              <div class="flex items-center gap-2">
                <span> Phương thức thanh toán:</span>
                <span>
                  @if(orderDetails.paidMethod === PaymentMethod.momo){MoMo} @if(orderDetails.paidMethod ===
                  PaymentMethod.atm){ATM nội địa} @if(orderDetails.paidMethod === PaymentMethod.visa){Thẻ quốc tế}
                  @if(orderDetails.paidMethod === PaymentMethod.bankTransfer){Chuyển khoản}&nbsp;
                </span>
                <i
                  class="icon"
                  [ngClass]="{
                    'icon-BankTransfer': orderDetails.paidMethod === PaymentMethod.bankTransfer,
                    'icon-Momo': orderDetails.paidMethod === PaymentMethod.momo,
                    'icon-ATM': orderDetails.paidMethod === PaymentMethod.atm,
                    'icon-Visa': orderDetails.paidMethod === PaymentMethod.visa
                  }"
                ></i>
              </div>
              <span> Thời gian: {{ orderDetails.createdAtDisplay }} </span>
              <span>
                Trạng thái:
                <b
                  [ngClass]="{
                    'text-darkYellow-1':
                      orderDetails.status === OrderStatus.pending || orderDetails.status === OrderStatus.manualPaid,
                    'text-darkEmerald': orderDetails.status === OrderStatus.completed,
                    'text-red-0': orderDetails.status === OrderStatus.paymentFailed
                  }"
                  >{{ orderDetails.statusDisplay }}</b
                >
              </span>
              <div class="flex justify-between" *ngIf="orderDetails.status === OrderStatus.pending">
                <span>Thời gian còn lại: {{ orderCountdown }}</span>
                <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="viewAndPay(orderDetails)">
                  Tiếp tục thanh toán
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="flex flex-col">
            <p *ngIf="orderDetails.status === OrderStatus.completed">
              Trạng thái “<b class="text-darkEmerald">Hoàn tất</b>" thể hiện khi giao dịch của bạn đã được xác nhận và
              vật phẩm đã được gửi đến bạn.
            </p>
            <p *ngIf="orderDetails.status === OrderStatus.manualPaid">
              Trạng thái “<b class="text-darkYellow-1">Đang xử lí</b>" thể hiện khi bạn đã hoàn tất thanh toán và đang
              đợi Kyons xác nhận giao dịch. Khi xác nhận thành công, vật phẩm sẽ được gửi đến bạn.
            </p>
            <p *ngIf="orderDetails.status === OrderStatus.pending">
              Trạng thái “<b class="text-red-0">Chưa thanh toán</b>" thể hiện khi bạn đã tạo giao dịch nhưng chưa thanh
              toán.
            </p>
            <p *ngIf="orderDetails.status === OrderStatus.paymentFailed">
              Trạng thái “<b class="text-red-0">Thanh toán thất bại</b>" thể hiện khi bạn đã tạo giao dịch nhưng chưa
              thanh toán.
            </p>
            <p *ngIf="orderDetails.status === OrderStatus.canceled">
              Trạng thái “<b>Đã thu hồi</b>" thể hiện khi bạn đã tạo giao dịch mới trong lúc đang sử dụng dịch vụ trả
              phí và đã thanh toán giao dịch mới.
            </p>
            <p>
              Lưu ý: Nếu bạn nghĩ rằng đã có lỗi trong quá trình giao dịch, liên hệ
              <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để được giải quyết nhanh
              chóng nhé.
            </p>
          </div>
        </div>
        } @else{
        <h5>Lịch sử giao dịch</h5>
        @if(orders&&orders.length>0){
        <table class="text-center border-separate border-spacing-y-4 hidden md:table">
          <tr>
            <th class="text-left">Sản phẩm</th>
            <th>Thời gian</th>
            <th>Thành tiền</th>
            <th>Trạng thái</th>
            <th class="text-right">Phương thức thanh toán</th>
          </tr>
          <tr *ngFor="let order of orders">
            <th
              class="text-left cursor-pointer underline"
              (click)="viewOrder(order)"
              [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
            >
              {{ order.orderPackage.name }}
            </th>
            <td>{{ order.createdAtDisplay }}</td>
            <td>{{ order.totalPriceDisplay }}</td>
            <td
              class="font-bold"
              [ngClass]="{
                'text-darkYellow-1': order.status === OrderStatus.manualPaid,
                'text-darkEmerald': order.status === OrderStatus.completed,
                'text-red-0': order.status === OrderStatus.pending || order.status === OrderStatus.paymentFailed
              }"
            >
              {{ order.statusDisplay }}
            </td>
            <td class="text-right">
              <div class="flex items-center justify-end gap-2">
                <i
                  class="icon"
                  [ngClass]="{
                    'icon-BankTransfer': order.paidMethod === PaymentMethod.bankTransfer,
                    'icon-Momo': order.paidMethod === PaymentMethod.momo,
                    'icon-ATM': order.paidMethod === PaymentMethod.atm,
                    'icon-Visa': order.paidMethod === PaymentMethod.visa
                  }"
                ></i>
                @if(order.paidMethod === PaymentMethod.momo){MoMo} @if(order.paidMethod === PaymentMethod.atm){ATM nội
                địa} @if(order.paidMethod === PaymentMethod.visa){Thẻ quốc tế} @if(order.paidMethod ===
                PaymentMethod.bankTransfer){Chuyển khoản}
              </div>
            </td>
          </tr>
        </table>
        }@else{
        <div class="flex-col">
          <i>Bạn chưa thực hiện giao dịch nào</i>
        </div>
        }
        <div class="md:hidden flex flex-col gap-4">
          @for (order of orders; track order.id; let i = $index){
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <div class="flex justify-between items-center">
                <div class="flex flex-col">
                  <a (click)="viewOrder(order)" class="font-bold">{{ order.orderPackage.name }}</a>
                  <span>{{ order.createdAtDisplay }}</span>
                </div>
                <strong
                  [ngClass]="{
                    'text-darkYellow-1': order.status === OrderStatus.manualPaid,
                    'text-darkEmerald': order.status === OrderStatus.completed,
                    'text-red-0': order.status === OrderStatus.pending || order.status === OrderStatus.paymentFailed
                  }"
                >
                  {{ order.statusDisplay }}
                </strong>
              </div>
              <div class="flex justify-between">
                <span>Thành tiền</span>
                <strong>{{ order.totalPriceDisplay }}</strong>
              </div>
              <div class="flex justify-between">
                <span>Mã thanh toán</span>
                <div class="flex gap-1.5 items-center">
                  <strong>{{ order.code }}</strong>
                  <button (click)="copy(order.code)">
                    <i class="icon-Duplicate" [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex justify-end gap-1 items-center">
              <a (click)="viewOrder(order)">Chi tiết giao dịch</a>
              <i class="icon-ChevronRight" [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"></i>
            </div>
          </div>
          <hr *ngIf="i > 0" />
          } @empty {
          <p>Chưa có giao dịch nào.</p>
          }
        </div>
        }
      </div>
      } }
    </div>
    <div class="h-20 md:hidden"></div>
  </div>
  <div
    *ngIf="
      orderProcessStatus() === OrderProcessStatus.selectingPayment ||
      orderProcessStatus() === OrderProcessStatus.ordering
    "
    class="fixed top-0 left-0 w-full md:h-screen h-full md:overflow-hidden overflow-auto flex flex-col md:flex-row items-start justify-center md:p-4 kyons-bg z-20 md:gap-8"
  >
    <div
      class="w-full max-w-[780px] flex justify-center md:on-screen bg-transparent md:rounded-lg md:px-8 px-4 h-full overflow-y-auto"
    >
      <div class="flex flex-col max-w-[616px] w-full gap-4 md:gap-8 py-8 h-fit">
        <h5 class="md:text-2xl">Đang mua gói {{ selectedPackage.name }}</h5>
        <div class="flex flex-col items-center sm:items-start gap-2.5">
          <div class="flex justify-between w-full">
            <h6 class="semi title">Chi tiết thanh toán</h6>
            <button
              class="font-semibold"
              [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
              (click)="orderProcessStatus.set(OrderProcessStatus.initial)"
              [disabled]="orderProcessStatus() === OrderProcessStatus.ordering"
            >
              Thay đổi?
            </button>
          </div>
          <div class="flex flex-col gap-4 p-6 rounded-lg border border-b-gray-300 w-full on-screen md:bg-transparent">
            <div class="flex flex-col gap-2.5 h-full">
              <span class="h7 flex items-center gap-2">
                <span class="buttoncard-text">{{ selectedPackage.packageTypeDisplay }}</span>
                <span
                  *ngIf="selectedPackage.isUsing"
                  class="text-white rounded-full uppercase px-2 py-0.5 text-xs"
                  [ngClass]="{ 'bg-orange': !isAI, 'bg-lightBlue-1': isAI }"
                  >Đang sử dụng</span
                >
              </span>
              <div class="flex items-center">
                <div class="flex gap-2 items-center">
                  <img [src]="selectedPackage.image" alt="" class="w-8 h-8" />
                  <h6 [ngClass]="{ 'text-lightBlue-1': selectedPackage.level > 0 }">{{ selectedPackage.name }}</h6>
                </div>
                <div class="flex-1"></div>
                <h6 *ngIf="selectedPackage.price === 0">Miễn phí</h6>
                <h6 *ngIf="selectedPackage.price > 0">
                  {{ selectedPackage.formattedSalePrice }}
                  <!-- <sub class="text-sm font-normal">/ {{ selectedPackage.duration / 24 }} ngày</sub> -->
                </h6>
              </div>
              <hr />
              <div class="flex flex-col">
                <details>
                  <summary><span class="h7 buttoncard-text">Quyền lợi</span></summary>
                  @if(selectedPackage.price === 0){
                  <ul class="check-list">
                    <li>Lộ trình học <strong>cơ bản</strong></li>
                  </ul>
                  } @else{
                  <div [innerHTML]="selectedPackage.description | safeHtml" class="check-list-wrapper"></div>
                  }
                </details>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2.5">
          <h6 class="semi title">Chọn hình thức thanh toán</h6>
          <div class="flex flex-col gap-4">
            <label
              class="flex items-center gap-2 md:gap-4 rounded-lg border border-blueGray-300 px-6 py-4 radio flat on-screen md:bg-transparent"
              [ngClass]="{ 'hover:border-orange': !isAI, 'hover:border-lightBlue-1': isAI }"
              [ngClass]="{
                'border-orange': !isAI && payment() === PaymentMethod.momo,
                'border-lightBlue-1': isAI && payment() === PaymentMethod.momo
              }"
            >
              <input
                type="radio"
                (change)="payment.set(PaymentMethod.momo)"
                [checked]="payment() === PaymentMethod.momo"
              />
              <i class="icon-Momo icon"></i>
              <span>Thanh toán bằng Ví MoMo</span>
            </label>
            <label
              class="flex items-center gap-2 md:gap-4 rounded-lg border border-blueGray-300 px-6 py-4 radio flat on-screen md:bg-transparent"
              [ngClass]="{ 'hover:border-orange': !isAI, 'hover:border-lightBlue-1': isAI }"
              [ngClass]="{
                'border-orange': !isAI && payment() === PaymentMethod.atm,
                'border-lightBlue-1': isAI && payment() === PaymentMethod.atm
              }"
            >
              <input
                type="radio"
                (change)="payment.set(PaymentMethod.atm)"
                [checked]="payment() === PaymentMethod.atm"
              />
              <i class="icon-ATM icon"></i>
              <span>Thanh toán bằng Thẻ ATM nội địa</span>
            </label>
            <label
              class="flex items-center gap-2 md:gap-4 rounded-lg border border-blueGray-300 px-6 py-4 radio flat on-screen md:bg-transparent"
              [ngClass]="{ 'hover:border-orange': !isAI, 'hover:border-lightBlue-1': isAI }"
              [ngClass]="{
                'border-orange': !isAI && payment() === PaymentMethod.visa,
                'border-lightBlue-1': isAI && payment() === PaymentMethod.visa
              }"
            >
              <input
                type="radio"
                (change)="payment.set(PaymentMethod.visa)"
                [checked]="payment() === PaymentMethod.visa"
              />
              <i class="icon-Visa icon"></i>
              <span>Thanh toán bằng thẻ quốc tế (Visa/Master/JCB)</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="md:w-[548px] w-full flex flex-col gap-6">
      <div
        class="flex flex-col w-full gap-4 md:gap-8 on-screen shadow-2 md:shadow-none md:rounded-lg md:p-8 p-4 relative"
      >
        <h6 class="semi title">Đơn hàng của bạn</h6>
        <div class="flex gap-2">
          <input type="text" placeholder="Nhập mã Voucher" [(ngModel)]="voucher" class="w-full" />
          <button
            class="btn link min-w-fit"
            [ngClass]="{ orange: !isAI, lightBlue: isAI }"
            (click)="applyVoucher()"
            [disabled]="checkingVoucher"
          >
            Nhập mã
          </button>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex justify-between items-center buttoncard-text">
            <span class="h7 semi">{{ selectedPackage.name }}</span>
            <span class="font-medium">{{ selectedPackage.formattedSalePrice }}</span>
          </div>
          @if(hasVoucherDiscount) {
          <div class="flex justify-between items-center text-darkEmerald">
            <span class="h7 semi">Voucher</span>
            <span class="font-medium">- {{ formattedVoucherDiscount }}</span>
          </div>
          }
          <div class="hidden md:flex justify-between border-t border-blueGray-300 pt-4">
            <h5>Tổng cộng</h5>
            <h5>{{ totalPrice }}</h5>
          </div>
          <div class="md:hidden flex justify-between border-t border-blueGray-300 pt-4">
            <h5 class="semi">Tổng cộng</h5>
            <h5 class="semi">{{ totalPrice }}</h5>
          </div>
        </div>
        <button
          class="btn md:flex-1 w-full"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          (click)="order()"
          [disabled]="orderProcessStatus() === OrderProcessStatus.ordering"
        >
          {{ orderProcessStatus() === OrderProcessStatus.ordering ? 'Đang xử lý...' : 'Mua gói' }}
        </button>
      </div>
      <span class="note hidden md:inline-block"
        >Lưu ý: Nếu bạn nghĩ rằng đã có lỗi trong quá trình giao dịch, liên hệ
        <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để được giải quyết nhanh chóng
        nhé.</span
      >
    </div>
  </div>
  <!-- <div
    *ngIf="orderProcessStatus() === OrderProcessStatus.ordering"
    class="fixed top-0 left-0 w-full h-screen overflow-auto flex md:items-center items-start justify-center md:p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen md:rounded-lg p-6">
      <h3>Bạn đang mua {{ selectedPackage.name }}</h3>
      <div class="flex flex-col gap-2.5">
        <h4>
          Mã thanh toán:&nbsp;<span class="text-lightBlue-1">{{ orderCode }}</span>
        </h4>
        <p><b>Quét mã QR</b> bằng ứng dụng Internet Banking hoặc <b>sao chép thông tin chuyển khoản</b> dưới đây:</p>
      </div>
      <div class="flex flex-col sm:flex-row items-center sm:items-start gap-4">
        <img
          [src]="
            'https://img.vietqr.io/image/TCB-19037780961051-compact2.png?amount=' +
            selectedPackage.salePrice +
            '&amp;addInfo=' +
            orderCode +
            '&amp;accountName=KYONS LLC'
          "
          alt="Bạn đang đăng ký Gói đặt trước ưu đãi (1 tháng)"
          class="sm:w-[50%]"
        />
        <div class="flex flex-col sm:flex-1 sm:items-start w-full">
          <div class="h-3"></div>
          <h2 class="text-lg font-bold text-center sm:text-left">Số tiền: {{ selectedPackage.formattedSalePrice }}</h2>
          <div class="h-6"></div>
          <div class="flex flex-col p-4 bg-blueGray-50 rounded-lg w-full">
            <div class="flex flex-col gap-1.5">
              <span>Nội dung chuyển khoản</span>
              <a class="flex justify-between cursor-pointer no-underline" (click)="copy(orderCode)">
                <strong id="copyText" class="text-orange"
                  ><span id="code">{{ orderCode }}</span></strong
                >
                <i class="icon-Duplicate text-orange text-base"></i>
              </a>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Ngân hàng</span>
              <span id="copyBank" class="flex justify-between cursor-pointer" (click)="copy('Techcombank')">
                <strong>Techcombank</strong>
                <i class="icon-Duplicate text-orange text-base"></i>
              </span>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Số tài khoản</span>
              <span id="copyCard" class="flex justify-between cursor-pointer" (click)="copy('19037780961051')">
                <strong>19037780961051</strong>
                <i class="icon-Duplicate text-orange text-base"></i>
              </span>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Tên chủ tài khoản</span>
              <span id="copyName" class="flex justify-between cursor-pointer" (click)="copy('KYONS LLC')">
                <strong>KYONS LLC</strong>
                <i class="icon-Duplicate text-orange text-base"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex md:flex-row flex-col md:items-center md:justify-between items-stretch justify-center gap-3">
        <button class="btn outlined"
                  [ngClass]="{ 'orange': !isAI, 'lightBlue': isAI }" (click)="cancelOrder()" [disabled]="isCanceling">
          {{ isCanceling ? 'Đang xử lý...' : 'Hủy giao dịch' }}
        </button>
        <button class="btn"
                  [ngClass]="{ 'orange': !isAI, 'lightBlue': isAI }" (click)="confirmOrder()" [disabled]="isConfirming">
          {{ isConfirming ? 'Đang xử lý...' : 'Xác nhận đã chuyển khoản' }}
        </button>
        <div class="md:hidden h-10"></div>
      </div>
    </div>
  </div> -->

  <div
    *ngIf="showTopup"
    class="fixed top-0 left-0 w-full h-screen overflow-auto flex md:items-center items-start justify-center md:p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen md:rounded-lg p-6">
      <h3>Bạn đang nạp tiền vào tài khoản</h3>
      <p>
        Vui lòng nhập số tiền và dùng mã thanh toán hoặc thông tin bên dưới để chuyển khoản đến KYONS, sau đó thông báo
        cho KYONS qua fanpage để được nạp nhanh nhất nhé
      </p>
      <input type="number" placeholder="Số tiền" [(ngModel)]="amount" step="50000" />
      <div class="flex flex-col sm:flex-row items-center sm:items-start">
        <img
          [src]="
            'https://img.vietqr.io/image/TCB-19037780961051-compact2.png?amount=' +
            amount +
            '&amp;addInfo=' +
            currentUser().email +
            '&amp;accountName=KYONS LLC'
          "
          class="sm:w-[50%]"
        />
        <div class="flex flex-col sm:flex-1 sm:items-start w-full">
          <div class="h-3"></div>
          <h2 class="text-lg font-bold text-center sm:text-left">Số tiền: {{ amount }}</h2>
          <div class="h-6"></div>
          <div class="flex flex-col p-4 bg-blueGray-50 rounded-lg w-full">
            <div class="flex flex-col gap-1.5">
              <span>Nội dung chuyển khoản</span>
              <a class="flex justify-between cursor-pointer no-underline">
                <strong [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
                  ><span id="code">{{ currentUser().email }}</span></strong
                >
                <i class="icon-Duplicate text-orange text-base" (click)="copy(currentUser().email)"></i>
              </a>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Ngân hàng</span>
              <span id="copyBank" class="flex justify-between cursor-pointer">
                <strong>Techcombank</strong>
                <i class="icon-Duplicate text-base" [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"></i>
              </span>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Số tài khoản</span>
              <span id="copyCard" class="flex justify-between cursor-pointer">
                <strong>19037780961051</strong>
                <i class="icon-Duplicate text-base" [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"></i>
              </span>
            </div>
            <div class="flex flex-col gap-1.5">
              <span>Tên chủ tài khoản</span>
              <span id="copyName" class="flex justify-between cursor-pointer">
                <strong>KYONS LLC</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between gap-4 flex-col md:flex-row">
        <button
          class="btn outlined w-full md:w-[200px]"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          (click)="showTopup = false"
        >
          Quay lại
        </button>
        <a
          class="btn w-full md:flex-1"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          href="https://www.facebook.com/KyonsVN"
          target="_blank"
          >Thông báo đã chuyển khoản</a
        >
      </div>
      <div class="h-12 md:hidden"></div>
    </div>
  </div>
  <div
    *ngIf="showBenefit"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] on-screen rounded-lg p-6 flex flex-col gap-4">
      <div class="listWrapper" [innerHTML]="currentPackage.description | safeHtml"></div>
      <button class="btn w-full" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="showBenefit = false">
        Ok nha
      </button>
    </div>
  </div>
  <div
    *ngIf="showExtending"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <img src="/assets/images/warning.webp" alt="" class="w-[100px] self-center" />
      <h3>Khoan! Gói {{ currentPackage.name }} vẫn còn hiệu lực</h3>
      <p>
        Gói học của bạn vẫn còn hiệu lực đấy!
        <b>Hệ thống sẽ tự động huỷ tài khoản tính phí & hoàn tiền phần chưa sử dụng vào tài khoản Kyons của bạn</b
        >.<br />Nếu có thắc mắc, hãy liên hệ
        <a
          href="https://www.facebook.com/KyonsVN"
          target="_blank"
          class="font-bold underline"
          [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
          >Kyons fanpage</a
        >
        để được hỗ trợ nhé!
      </p>
      @if(extendingCooldown<=0){
      <div class="flex gap-4 justify-between">
        <button
          class="btn"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          (click)="selectPayment(currentPackage); showExtending = false"
        >
          Tôi chắc chắn gia hạn gói!
        </button>
        <button class="btn outlined" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="showExtending = false">
          Quay lại
        </button>
      </div>
      } @else{
      <div class="flex gap-4 justify-center">
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" disabled>
          Gia hạn ({{ extendingCooldown }} giây)
        </button>
      </div>
      }
    </div>
  </div>
  <div
    *ngIf="orderProcessStatus() === OrderProcessStatus.warning"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h3>Bạn đang sử dụng gói {{ currentPackage.name }}</h3>
      <p>
        Thời gian sử dụng vẫn còn, bạn cần phải hủy gói đang dùng trước khi thay đổi? Vui lòng liên hệ
        <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để được giải quyết nhanh chóng nhé!
      </p>
      <div class="flex gap-4 justify-end">
        <button
          class="btn"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          (click)="orderProcessStatus.set(OrderProcessStatus.initial)"
        >
          Đã hiểu
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="showUpgrade"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h3>Bạn đang sử dụng gói {{ currentPackage.name }}</h3>
      <div class="col">
        <p>
          Thời gian sử dụng vẫn còn, nếu chọn nâng cấp gói thời gian sử dụng còn lại sẽ bị đóng băng. Sau khi thanh toán
          thành công gói mới bạn sẽ được hoàn tiền theo thời gian còn lại của gói cũ (nếu có) vào tài khoản KYONS của
          mình. Bạn muốn tiếp tục nâng cấp gói?
        </p>
        <p>
          Vui lòng liên hệ <a href="https://www.facebook.com/KyonsVN" target="_blank">Kyons Fanpage</a> để được tư vấn!
        </p>
      </div>
      <div class="flex gap-4 justify-between">
        <button class="btn outlined" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="showUpgrade = false">
          Quay lại
        </button>
        <button
          class="btn"
          [ngClass]="{ orange: !isAI, lightBlue: isAI }"
          (click)="selectPayment(selectedPackage); showUpgrade = false"
        >
          Tiếp tục nâng cấp
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="orderProcessStatus() === OrderProcessStatus.success"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h3 class="self-center"><img src="assets/icons/Succeed.svg" alt="success" /></h3>
      <h5 class="self-center">Thanh toán thành công!</h5>
      <p>
        Kyons vừa gửi email cho bạn để xác nhận thanh toán thành công. Bạn sẽ được đưa về trang
        <b> “Lịch sử giao dịch” </b> trong vòng {{ countdown }} giây.
      </p>
      <p>
        Nếu vẫn chưa nhận được email xác nhận, bạn hãy liên hệ
        <a
          href="https://www.facebook.com/KyonsVN"
          target="_blank"
          class="font-bold underline"
          [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
          >Kyons fanpage</a
        >
        để được hỗ trợ nhé!
      </p>
      <div class="flex gap-3 justify-center">
        <button class="btn outlined" [ngClass]="{ orange: !isAI, lightBlue: isAI }" [routerLink]="paths.home.path">
          Về trang chủ
        </button>
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="backToHistory()">
          Xem lịch sử giao dịch
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="orderProcessStatus() === OrderProcessStatus.fail"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[700px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h3 class="self-center"><img src="assets/icons/Cancel.svg" alt="fail" /></h3>
      <h5 class="self-center">Đã có lỗi xảy ra!</h5>
      @if(hasError!==''){
      <b class="text-red-0">{{ hasError }}</b>
      }
      <div class="col">
        <p>
          Kyons đã nhận được thông tin và đang tiến hành xử lí. Nếu có lỗi ngoài ý muốn, bạn có thể xem trạng thái của
          giao dịch trong
          <b>“Lịch sử giao dịch”</b> và hãy gửi hình chụp giao dịch đến
          <a
            href="https://www.facebook.com/KyonsVN"
            target="_blank"
            class="font-bold underline"
            [ngClass]="{ 'text-orange': !isAI, 'text-lightBlue-1': isAI }"
            >Kyons fanpage</a
          >
          để được hỗ trợ nhé!
        </p>
      </div>
      <div class="flex gap-3 justify-center">
        <button class="btn outlined" [ngClass]="{ orange: !isAI, lightBlue: isAI }" [routerLink]="paths.home.path">
          Về trang chủ
        </button>
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="backToHistory()">
          Xem lịch sử giao dịch
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="isCancelFail"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[443px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h5 class="text-center">Hủy giao dịch không thành công</h5>
      <div class="flex gap-4 justify-center">
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="backToHistory()">
          Ok, thử lại sau
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="isPendingOrder"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[443px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h5 class="text-center">
        Bạn đang có một đơn hàng trong trạng thái chờ duyệt nên không thể tiếp tục hành động này.
      </h5>
      <div class="flex gap-4 justify-center">
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="backToHistory()">Xem</button>
      </div>
    </div>
  </div>
  <div
    *ngIf="isOrderFail"
    class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 bg-blueGray-100 z-20"
  >
    <div class="w-full max-w-[443px] flex flex-col gap-6 on-screen rounded-lg p-6">
      <h5 class="text-center">Có lỗi, xin thử lại</h5>
      <div class="flex gap-4 justify-center">
        <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="backToHistory()">Quay lại</button>
      </div>
    </div>
  </div>
</div>

<dialog #coinDialog (click)="coinDialog.close()">
  <div class="col w-full items-center gap-6 p-6 pt-10 on-screen" (click)="$event.stopPropagation()">
    <span>Tính năng sắp ra mắt</span>
    <button class="btn" [ngClass]="{ orange: !isAI, lightBlue: isAI }" (click)="coinDialog.close()">Đã hiểu</button>
  </div>
</dialog>
